import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {createUserInteraction} from "../../../../actions";
import * as URI from "urijs";
import AddABlog from './AddABlog';
import Toolbox from "../../../Common/Toolbox";
import {translate} from "../../../../actions/i18n";
import AddTikTok from "./AddTikTok";
import AddInstagram from "./AddInstagram";

class SocialAuthConnector extends Component {

    static defaultProps = {
        types: ["Blog", "Instagram Business", /*"Instagram",*/ "Twitter", "Pinterest", "Youtube", "Facebook", "Facebook Page", "TikTok"],
        buttonClass: 'v3 btn btn-secondary medium',
        inline: false,
    };

    static propTypes = {
        user: PropTypes.object.isRequired,
        types: PropTypes.array.isRequired,
        onDidAttach: PropTypes.func.isRequired,
        onFail: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,

        autolaunch: PropTypes.bool,
        inline: PropTypes.bool,
        buttonText: PropTypes.node,
        buttonClass: PropTypes.string,
        buttonStyle: PropTypes.object,
        toolboxAddlClasses: PropTypes.string,
        authLinks: PropTypes.object // provide these to skip loading via api
    };

    state = {
        isFetchingAuthLinks: false,
        didFetchAuthLinks: false,
        authLinks: {},
        showBlogToolbox: false,
        showTikTokToolbox: false,
        showInstagramToolbox: false
    };

    componentDidMount() {

        if (this.props.authLinks) {
            // Provided authLinks from the outside, set them to state
            this.setState({authLinks: this.props.authLinks});
        } else {
            this.setState({isFetchingAuthLinks: true});
            this.props.createInteraction(this.props.user.id, 'SocialAuthRequest')
                .then((resp) => {
                    if (resp.meta.error) {
                        this.setState({isFetchingAuthLinks: false});
                        this.props.onFail(resp.meta.error);
                    } else {
                        this.setState({
                            authLinks: resp.data.results, isFetchingAuthLinks: false, didFetchAuthLinks: true
                        })
                    }
                });
        }

        if (this.props.autolaunch && this.props.types.length === 1) {
            if (this.props.types[0] === 'Instagram Business') {
                this.setState({showInstagramToolbox: true});
                this.launchAuthWindow('instagram-business');
            } else if (this.props.types[0] === 'Blog') {
                this.setState({showBlogToolbox: true});
            } else if (this.props.types[0] === 'TikTok') {
                this.setState({showTikTokToolbox: true});
            }
        }

        window.addEventListener('message', this.handleAuthWindowMessage.bind(this));
    }

    launchAuthWindow(type) {
        const authLink = this.state.authLinks[type] || this.state.authLinks[type.replace(/\s+/g, '-').toLowerCase()] || this.props.authLinks[type] || this.props.authLinks[type.replace(/\s+/g, '-').toLowerCase()];
        if (!authLink) {
            console.log("No authlink, skipping");
            return;
        }
        let url = new URI(authLink);

        if (this.authWindow) {
            this.authWindow.close();
        }

        // this.authWindow = window.open(url.toString(), '_blank', 'height=600,width=800');
        // Trying the simpler form out to see if it helps our mobile issues:
        this.authWindow = window.open(url.toString());
    }

    handleAuthWindowMessage(msg) {
        const {data} = msg;

        if (data.from !== 'AttachSocial') {
            return;
        }

        this.setState({isAttachingSocial: true});

        this.props.createInteraction(this.props.user.id, 'AttachSocial', {'token': data.t, 'type': data.type})
            .then((resp) => {
                if (resp.meta.error) {
                    this.props.onFail(resp.meta.error);
                } else {
                    this.props.onDidAttach();
                    if (this.authWindow) {
                        this.authWindow.close();
                    }
                }
            })
    }

    renderBlogLink(){
        const buttonText = this.props.buttonText || this.props.translate('contribute.social.connect.add_a_blog', 'Add a Blog');

            return <a
                role="button"
                className={this.props.buttonClass}
                style={this.props.buttonStyle}
                onClick={this.toggleAddBlog.bind(this)}
                >
                {!this.props.inline && <i className={"v3 icon rss"} style={{marginRight: 5}}/>}
                {buttonText}
            </a>
    }

    toggleAddBlog() {
        this.setState({showBlogToolbox: !this.state.showBlogToolbox})
    }

    renderBlogToolbox() {
        return (<Toolbox
            addlClasses={this.props.toolboxAddlClasses}
            title={this.props.translate('contribute.social.connect.add_a_blog', 'Add a Blog')}
            content={<AddABlog
                user={this.props.user}
                onClose={()=>{
                    this.toggleAddBlog();
                    this.props.onDidAttach();
                }}
            />}
            onClose={this.toggleAddBlog.bind(this)}
            style={this.props.toolboxAddlClasses ? undefined : {
                width: 500,
                left: '50%',
                marginLeft: -250,
                maxHeight: 600,
                top: 0
            }}
        />)
    }

    renderTikTokLink(){
        const buttonText = this.props.buttonText || this.props.translate('contribute.social.connect.add_a_tiktok', 'Add a TikTok');

        return <a
            role="button"
            className={this.props.buttonClass}
            style={this.props.buttonStyle}
            onClick={this.toggleAddTikTok.bind(this)}
        >
            {!this.props.inline && <i className={"fab fa-tiktok"} style={{marginRight: 5}}/>}
            {buttonText}
        </a>
    }

    toggleAddTikTok() {
        this.setState({showTikTokToolbox: !this.state.showTikTokToolbox})
    }
    renderTikTokToolbox() {
        return (<Toolbox
            addlClasses={this.props.toolboxAddlClasses}
            supportMobile={true}
            title={this.props.translate('contribute.social.connect.add_a_tiktok', 'Add a TikTok')}
            content={<AddTikTok
                userId={this.props.user.id}
                onClose={()=>{
                    this.toggleAddTikTok();
                    this.props.onDidAttach();
                }}
            />}
            onClose={this.toggleAddTikTok.bind(this)}
            style={this.props.toolboxAddlClasses ? undefined : {
                width: 500,
                left: '50%',
                marginLeft: -250,
                maxHeight: 600,
                top: 0
            }}

        />)
    }

    renderInstagramLink() {
        const buttonText = this.props.buttonText || this.props.translate('contribute.social.connect.add_an_instagram', 'Add an Instagram');

        return <a
            role="button"
            className={this.props.buttonClass}
            style={this.props.buttonStyle}
            onClick={this.toggleAddInstagram.bind(this)}
        >
            {!this.props.inline && <i className={"fab fa-instagram"} style={{marginRight: 5}}/>}
            {buttonText}
        </a>
    }

    toggleAddInstagram() {
        this.setState({showInstagramToolbox: !this.state.showInstagramToolbox})
    }

    renderInstagramToolbox() {
        return (<Toolbox
            addlClasses={this.props.toolboxAddlClasses}
            supportMobile={true}
            title={this.props.translate('contribute.social.connect.add_an_instagram', 'Add an Instagram')}
            content={<AddInstagram
                onClickInstagramBusinessLink={() => {
                    this.toggleAddInstagram();
                    this.launchAuthWindow('instagram-business')
                }}
                userId={this.props.user.id}
                onClose={()=>{
                    this.toggleAddInstagram();
                    this.props.onDidAttach();
                }}
            />}
            onClose={this.toggleAddInstagram.bind(this)}
            style={this.props.toolboxAddlClasses ? undefined : {
                width: 500,
                left: '50%',
                marginLeft: -250,
                maxHeight: 600,
                top: 0
            }}

        />)
    }

    renderYouTubeLink() {
        const _t = this.props.translate;
        const text = _t('contribute.social.connect.connect_to', "Connect to ");
        let niceName = text + 'YouTube';
        const buttonText = this.props.buttonText || niceName;
        return (
            <a
                role='button'
                className={this.props.buttonClass}
                style={this.props.buttonStyle}
                onClick={this.launchAuthWindow.bind(this, 'youtube')}
            >
                <img
                    style={{height: 20, marginTop: -5, marginRight: 10}}
                    src="https://cdn.tid.al/img/network/yt_icon_rgb.png" />
                {buttonText}
            </a>
        );

    }

    renderSocialLink(type, iconClass) {
        const _t = this.props.translate;
        const text = type === "Facebook Page" ? _t('contribute.social.connect.add_a', "Add a ") : _t('contribute.social.connect.connect_to', "Connect to ");
        let niceName = text + type;
        if (type === 'Instagram Business') {
            niceName = 'Instagram Business/Creator'
        }
        const buttonText = this.props.buttonText || niceName;
        const socialAuthType = type.replace(/\s+/g, '-').toLowerCase();

        if (type === 'Blog') {
            return this.renderBlogLink();
        }

        if (type === 'TikTok') {
            return this.renderTikTokLink();
        }

        if (type === 'Youtube') {
            return this.renderYouTubeLink();
        }

        if (type === 'Instagram Business') {
            return this.renderInstagramLink();
        }

        return (
            <a
                role='button'
                className={this.props.buttonClass}
                style={this.props.buttonStyle}
                onClick={this.launchAuthWindow.bind(this, socialAuthType)}
            >
                {iconClass && <i className={iconClass} style={{marginRight: 5}}/>}
                {buttonText}
            </a>
        );
    }

    renderSocialsDiv () {
        return this.props.types.map(type => {
            const iconClass = type === 'Facebook Page' ? 'v3 icon facebook' : `v3 icon ${type.toLowerCase()}`;

            return <div className={`add-social-type ${type.toLowerCase()}`}>
                {this.renderSocialLink(type, iconClass)}
                </div>
            })
    }

    renderContent() {
        return this.props.inline
            ? this.renderSocialLink(this.props.types[0])
            : (
                <div className="add-social-toolbox-inner">
                    {this.renderSocialsDiv()}
                </div>
            );
    }

    render(){
        return (
            <div>
                {this.state.showBlogToolbox && this.renderBlogToolbox()}
                {this.state.showTikTokToolbox && this.renderTikTokToolbox()}
                {this.state.showInstagramToolbox && this.renderInstagramToolbox()}
                {this.renderContent()}
            </div>
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        createInteraction: (id, type, context) => dispatch(createUserInteraction(id, type, context)),
        translate: (key, defaultValue) => dispatch(translate(key, defaultValue))
    };
};

const ConnectedSocialAuthConnector = connect(null, mapDispatchToProps)(SocialAuthConnector);

export default ConnectedSocialAuthConnector;
