import React, {useState, useEffect, useMemo} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchCampaigns, fetchCampaign, fetchUserSocials, createUserInteraction} from "../../../../actions";
import {getCampaign, getCampaigns} from "../../../../selectors/campaigns";
import {getCurrentUser} from "../../../../selectors/presence";
import Button from "../../../Common/Form/Button";
import Form from "../../../Common/Form/Form";
import CampaignTopContentSection from "../../../MAC/Creator/CampaignTopContentSection";
import {makeMomentFromDate} from "../../../../utilities";
import _get from 'lodash/get';
import {getUserSocials} from "../../../../selectors/users";
import SocialAuthConnector from "../../../Influencer/EditProfile/Templates/SocialAuthConnector";
import {translate} from "../../../../actions/i18n";
import InteractiveBriefViewer from "./InteractiveBriefViewer";
import moment from "moment";
import ReactMarkdown from "react-markdown";
import Toolbox from "../../../Common/Toolbox";
import UploadVideos from "../../../Influencer/Requirements/Templates/UploadVideos";
import StandaloneVideoUploader from "../../../Influencer/Requirements/StandaloneVideoUploader";

const ConnectSocialsSection = connect(
    (state, props) => ({
        socials: getUserSocials(state, {userId: 'me'})
    }),
    (dispatch) => bindActionCreators({fetchUserSocials, createUserInteraction, translate}, dispatch))
(function ({user, fetchUserSocials, socials, createUserInteraction, translate}) {

    const [authLinks, setAuthLinks] = useState(null);

    useEffect(() => {
        fetchUserSocials('me');
    }, []);

    useEffect(() => {
        if (user && user.id) {
            createUserInteraction(user.id, 'SocialAuthRequest')
                .then(resp => {
                    if (resp.meta.error) {
                    } else {
                        setAuthLinks(resp.data.results);
                    }
                })
        }
    }, [user]);

    if (!user || !user.id || !authLinks) {
        return null;
    }

    // Collect instagram and tiktok accounts, and then further filter by connection status
    const igs = (socials || []).filter(s => s.type === 'Tidal\\Social\\InstagramBusiness');
    const tts = (socials || []).filter(s => s.type === 'Tidal\\Social\\TikTok');

    const connectedIgs = igs.filter(s => !s.broken);
    const connectedTts = tts.filter(s => !s.broken);

    const igIconClass = connectedIgs.length > 0 ? 'v3 icon success' : 'v3 icon error';
    const ttIconClass = connectedTts.length > 0 ? 'v3 icon success' : 'v3 icon error';

    const handleDidAttach = () => {
        fetchUserSocials('me');
    };

    const connectorProps = {
        inline: true,
        user: user,
        onDidAttach: handleDidAttach,
        authLinks: authLinks,
        buttonClass: 'v3 btn tidal-btn btn-secondary medium',
        buttonStyle: {width: 300},
        toolboxAddlClasses: 'toolbox-fixed toolbox-sm'
    };

    const wdConnectSocials = translate('creator.brief.connect_socials.title', 'Connect Your Socials To Participate');
    const wdConnectInstagram = translate('creator.brief.connect_socials.connect_instagram', 'Connect to Instagram');
    const wdConnectTiktok = translate('creator.brief.connect_socials.connect_tiktok', 'Connect to TikTok');
    const overallSuccess = (connectedIgs.length > 0 && connectedTts.length > 0) ? <i className="v3 icon success" style={{marginRight: 20}}/> : null;

    const renderConnectorOrConnected = (socials, niceName, connector) => {
        if (socials && socials.length > 0) {
            const url = socials[0].url;
            return (
                <div>
                    <i className="v3 icon success" style={{marginRight: 8}} />
                    You are connected to {niceName}. {(url && <a className="bold" href={url} target="_blank" rel="noopener noreferrer">View Profile <i className="v3 icon external-link" /></a>)}
                </div>
            );
        }
        return connector;
    };

    return (
        <div className="v3 form-group">
            <h4 className="v3 h4">{overallSuccess}{wdConnectSocials}</h4>

            <div style={{marginBottom: 20, marginTop: 20}}>

                {renderConnectorOrConnected(connectedIgs, 'Instagram', (
                    <SocialAuthConnector
                        {...connectorProps}
                        types={['Instagram Business']}
                        buttonText={<>
                            <i className={igIconClass} style={{marginRight: 8}}/>
                            {wdConnectInstagram}
                        </>}
                    />
                ))}
            </div>


            <div style={{marginBottom: 20}}>

                {renderConnectorOrConnected(connectedTts, 'TikTok', (
                    <SocialAuthConnector
                        {...connectorProps}
                        types={['TikTok']}
                        buttonText={<>
                            <i className={ttIconClass} style={{marginRight: 8}}/>
                            {wdConnectTiktok}
                        </>}
                    />
                ))}
            </div>
        </div>

    );
});

function JoinCampaignButton({campaign, translate, onClickUpload}) {

    const invitationDeadline = makeMomentFromDate(_get(campaign, 'settings.invitation_deadline_at', null));
    const endDate = makeMomentFromDate(_get(campaign, 'end', null));
    const isDeadlinePast = invitationDeadline && invitationDeadline.isBefore(moment());
    const isClosed = endDate && endDate.isBefore(moment());
    console.log("end date", endDate, isClosed);
    const showButton = !isDeadlinePast && !isClosed;

    const wdTitle = translate('creator.brief.join_campaign.title', 'How to Join');

    // Copy for if the campaign is currently open
    const btnJoinCampaign = translate('creator.brief.join_campaign.button', 'Join this Campaign');
    const btnUploadVideo = translate('creator.brief.upload_video.button', 'Upload a Video');
    const wdJoinDescription = translate('creator.brief.join_campaign.description', 'This campaign requires you to read and accept the brief before you can participate. Click below to get started!');
    const wdInvitesClosedDescription = translate('creator.brief.join_campaign.invites_closed_description', 'This campaign is still running, but applications are now closed. Feel free to browse content from other creators in this campaign, below.');
    const wdClosedDescription = translate('creator.brief.join_campaign.closed_description', 'This campaign has ended. Feel free to browse content from other creators in this campaign, below.');

    let description = wdJoinDescription;
    if (isDeadlinePast) {
        description = wdInvitesClosedDescription;
    }
    if (isClosed) {
        description = wdClosedDescription;
    }


    return (
        <div className="form-group v3">
            <h4 className="v3 h4">{wdTitle}</h4>
            <p className="help-block">{description}</p>
            {showButton && <a className={'v3 btn tidal-btn btn-primary large'}
               href={ `/contribute/do/page/campaign/go?campaign=${campaign.slug}`}
            >{btnJoinCampaign}</a>}
            {showButton && <a className={'v3 btn tidal-btn btn-secondary large'}
               onClick={onClickUpload}
            >{btnUploadVideo}</a>}
        </div>
    );

}

function UseHashtagsSection({hashtags, translate}) {
    if (!hashtags || hashtags.length === 0) {
        return null;
    }
    const wdUseHashtags = translate('creator.brief.hashtags.title', 'Post on Social Using These Hashtags');
    const wdHashtagsHelp = translate('creator.brief.hashtags.help', 'Post content on Instagram and TikTok using the hashtags above.');
    return (
        <div className="form-group v3">
            <h4 className="v3 h4">{wdUseHashtags}</h4>
            {hashtags.map(hashtagObj => {
                const explodedTags = hashtagObj.value.split(', ');
                const title = hashtags.length > 1 ? <label>For {hashtagObj.campaign.name}:</label> : null;
                return (
                    <div key={'wrapper-'+hashtagObj.value}>
                        {title}
                        <ul>
                            {explodedTags.map(tag => <li key={tag}>{tag}</li>)}
                        </ul>
                    </div>
                );
            })}
            <p className="help-block">{wdHashtagsHelp}</p>

        </div>
    );
}

function InteractiveBriefSection({translate, briefs, onClickBrief}) {
    const wdReadFull = translate('creator.brief.launch.interactive', 'View The Full Brief');
    const count = briefs.length;

    return (
        <div className="form-group v3">
            <h4 className="v3 h4">{wdReadFull}</h4>
            {briefs.map((brief, index) => {
                const title = count > 1 ? <label>For {brief.campaign.name}:</label> : null;
                return (
                    <div key={index} style={{marginBottom: 20}}>
                        {title}
                        <Button
                            content={<>View Brief</>}
                            classes={['v3 btn-primary medium']}
                            onClick={() => {
                                onClickBrief(brief)
                            }}
                        />
                    </div>
                );
            })}
        </div>
    );
}

function DownloadBriefSection({attachments, translate}) {
    if (!attachments || attachments.length === 0) {
        return null;
    }

    const count = attachments.length;

    const wdReadFull = translate('creator.brief.download.read_full', 'Read The Full Brief');

    return (
        <div className="form-group v3">
            <h4 className="v3 h4">{wdReadFull}</h4>

            {attachments.map((attachment, index) => {
                const title = count > 1 ? <label>For {attachment.campaign.name}:</label> : null;
                return (
                    <div key={index} style={{marginBottom: 20}}>
                        {title}
                        <Button
                            content={<> <i className="v3 icon fa-file-pdf"/> Brief: {attachment.value.name}</>}
                            classes={['v3 btn-secondary medium']}
                            onClick={() => {
                                window.open(attachment.value.original_url, '_blank');
                            }}
                        />
                    </div>
                );
            })}

        </div>
    );
}

function UploadVideoPopup({translate, campaign, user, onClose}) {

    return (
        <Toolbox
            onClose={onClose}
            title={"Upload a Video"}
            content={<StandaloneVideoUploader
                campaign={campaign}
                user={user}
                translate={translate}
            />}
            addlClasses={"toolbox-fixed toolbox-sm"}
        />
    )

}

function AcceptBriefForm({campaign, subcampaigns, user, translate, onClickBrief}) {

    const [showUpload, setShowUpload] = useState(false);

    const collectItems = (key, includeParent = true) => {
        let out = [];
        for (const sub of subcampaigns) {
            const nItem = _get(sub, key, null);
            console.log("Collecting " + key + ' from sub ' + sub.name, nItem);
            if (nItem) {
                out.push({value: nItem, campaign: sub});
            }
        }
        const nItem = _get(campaign, key, null) ;
        console.log("Collecting " + key + ' from main ' + campaign.name, nItem);
        if (nItem && (includeParent || out.length===0)) {
            out.push({value: nItem, campaign});
        }
        return out;
    };

    const attachments = useMemo(() => collectItems('settings.invitation_attachment', false), [campaign, subcampaigns]);
    const briefIds = useMemo(() => collectItems('settings.brief_id', false), [campaign, subcampaigns]);
    const hashtags = useMemo(() => collectItems('settings.hashtag', false), [campaign, subcampaigns]);
    const description = useMemo(() => collectItems('description', false), [campaign, subcampaigns]);

    // determine if we're using hashtag monitor on any campaign or subcampaign
    const useHashtagMonitor = useMemo(() => {
        const vals = collectItems('settings.monitor_hashtag', true).map(item => item.value).filter(item => item);
        return vals.length > 0;
    }, [campaign, subcampaigns]);


    return (

        <div>
            {description.map((item, index) => {
                const title = description.length > 1 ? <label><strong>For {item.campaign.name}:</strong></label> : null;
                return (
                    <div key={index}>
                        {title}
                        <ReactMarkdown className="brief-description">{item.value}</ReactMarkdown>
                    </div>
                );
            })}
            {briefIds.length > 0 && <InteractiveBriefSection onClickBrief={onClickBrief} briefs={briefIds} translate={translate}/>}
            <DownloadBriefSection attachments={attachments} translate={translate} />
            {useHashtagMonitor && <ConnectSocialsSection user={user} /> }
            {useHashtagMonitor && <UseHashtagsSection hashtags={hashtags} translate={translate} /> }
            {useHashtagMonitor && <div><a className={'v3 btn tidal-btn btn-secondary large'} onClick={() => setShowUpload(true)}>Or Upload a Video Directly</a></div>}
            {!useHashtagMonitor && <JoinCampaignButton campaign={campaign} translate={translate} onClickUpload={() => setShowUpload(!showUpload)} />}
            {showUpload && <UploadVideoPopup translate={translate} campaign={campaign} user={user} onClose={() => setShowUpload(false)} />}
        </div>

    );
}

function MACCreatorBriefPage({fetchAuthenticated, fetchCampaign, campaignId, user, campaign, campaigns, fetchCampaigns, translate}) {
    const [activations, setActivations] = useState([]);
    const [showInteractiveBrief, setShowInteractiveBrief] = useState(null);

    const fetchActivations = async () => {
        const resp = await fetchAuthenticated('/contribute/api/user/me/activation?withStats=1');
        const json = await resp.json();
        setActivations(json.data);
    };

    useEffect(function() {
        Promise.all([
            fetchActivations(),
            fetchCampaign(campaignId),
        ]).then(() => console.log("Done loading MACCreatorBriefPage data"));
    }, []);

    const childCampaigns = (campaign || {}).subcampaigns || [];

    const matchingTeamCampaigns = useMemo(function () {

        return (childCampaigns || []).filter(_c => {
            if (!user || !user.teams || user.teams.length === 0) {
                return true;
            }
            if (!_c.billable_team_id) {
                return true;
            }
            if (user.teams.indexOf(_c.billable_team_id) > -1) {
                return true;
            }
            return false;
        });

    }, [user, childCampaigns]);

    if (!campaign) {
        return;
    }

    const imageUrl = campaign.image || campaign.fallback_image_url || 'https://dlbezd06tkli6.cloudfront.net/m/89d2c643174919bbbe5adb7404d10f1023538e06.jpg';
    const imageBgStyle = { backgroundImage: `url(${imageUrl})` };
    const endMoment = campaign.end ? makeMomentFromDate(campaign.end) : null;

    const wdDueDate = translate('creator.words.due_date', 'Due Date');
    const wdOpenEnded = translate('creator.words.open_ended', 'Open Ended');
    const wdSeeGuidelines = translate('creator.brief.see_guidelines', 'See Guidelines');

    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                {showInteractiveBrief && <InteractiveBriefViewer
                    campaign={showInteractiveBrief.campaign}
                    onClose={() => setShowInteractiveBrief(null)}
                />}

                <div className="brief-page-wrapper">

                    <div className="page-title-wrapper briefs-title-wrapper">
                        <h1 className="red-indent">{campaign.name}</h1>
                    </div>

                    <div className="brief-preview-wrapper">
                        <div className="brief-preview-left">

                            <div className="brief-preview-img-wrapper">
                                <div className="brief-preview-img" style={imageBgStyle} />
                            </div>

                            <div className="flex-parent" style={{textAlign: 'center'}}>
                                <div>
                                    <small className="grey">{wdDueDate}:</small><br />
                                    <strong>{endMoment ? endMoment.format('MMM Do YYYY') : wdOpenEnded}</strong>
                                </div>
                                <div style={{display: "none"}} id="brief-see-guidelines">
                                    <a href={`/contribute/do/page/campaign/go?campaign=${campaign.slug}`} className={'tidal-btn btn v3 btn-secondary medium square'} >{wdSeeGuidelines}</a>
                                </div>
                            </div>

                        </div>
                        <div className="brief-preview-right">
                            <AcceptBriefForm
                                campaign={campaign}
                                subcampaigns={matchingTeamCampaigns}
                                user={user}
                                translate={translate}
                                onClickBrief={brief => setShowInteractiveBrief(brief)}
                            />


                        </div>
                    </div>

                </div>

                <CampaignTopContentSection campaign={campaign} />
            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => {
    return {
        campaigns: getCampaigns(state, props),
        campaign: getCampaign(state, {campaignId: props.campaignId}),
        user: getCurrentUser(state, props)
    };
}
const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    fetchCampaign: fetchCampaign,
    fetchCampaigns: fetchCampaigns,
    translate: translate
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACCreatorBriefPage);
