import React from 'react';
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {getCurrentChannel, getPresenceTeam} from "../../../selectors/presence";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../actions/auth";
import {fetchPresence} from "../../../actions/presence";
import {fetchBadges} from "../../../actions";
import {translate} from "../../../actions/i18n";

function DashboardAffiliate({translate, channel}) {
    const title = translate('creator.sections.affiliate.title', 'Affiliate Earnings');
    const descBriefs = translate('creator.sections.affiliate.description', 'Build your shoppable page and earn commission on every sale.');
    const seeAllWord = translate('creator.words.see_all', 'See All');

    if (!channel || !channel.use_affiliate_offers) {
        return null;
    }

    return (
        <div className="dash-section">
            <div className="section-header">
                <h1 className="blue-indent">{title}
                    <Link to="/contribute/do/page/dash/briefs" className="seeall">{seeAllWord}<i className="v3 icon fa-arrow-right" /> </Link>
                </h1>
                <p className="section-subtitle">{descBriefs}</p>
            </div>

            <div className="section-content">

                <div className="link-tiles">
                    <div className="link-tile">
                        <Link to="/contribute/do/page/dash/aff_page" className="tile">
                            <i className="v3 icon fa-shopping-cart" />
                            <span className="title">Shoppable Page</span>
                            <span className="desc">Customize your shoppable page</span>
                        </Link>
                    </div>
                    <div className="link-tile">
                        <Link to="/contribute/do/page/dash/aff_search" className="tile">
                            <i className="v3 icon fa-link" />
                            <span className="title">Product Search</span>
                            <span className="desc">Find products to promote and earn</span>
                        </Link>
                    </div>
                    <div className="link-tile">
                        <Link to="/contribute/do/page/dash/aff_offers" className="tile">
                            <i className="v3 icon fa-funnel-dollar" />
                            <span className="title">View Offers</span>
                            <span className="desc">Current offers and deals from brands</span>
                        </Link>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default connect(
    function (state, props) {
        return {
            isLoading: !state.i18n.didFetch,
            team: getPresenceTeam(state, props),
            channel: getCurrentChannel(state, props)
        };
    },
    function (dispatch) {
        return bindActionCreators({
            fetchAuthenticated: fetchAuthenticated,
            fetchPresence: fetchPresence,
            fetchBadges: fetchBadges,
            translate: translate
        }, dispatch);
    }
)(DashboardAffiliate);