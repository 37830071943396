import React, {useEffect} from 'react';
import DashboardBriefs from "../../../MAC/Creator/DashboardBriefs";
import DashboardCommunity from "../../../MAC/Creator/DashboardCommunity";
import ProfileStripe from "../../../MAC/Creator/ProfileStripe";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchPresence} from "../../../../actions/presence";
import {fetchBadges} from "../../../../actions";
import DashboardTrending from "../../../MAC/Creator/DashboardTrending";
import DashboardSpotlight from "../../../MAC/Creator/DashboardSpotlight";
import {translate} from "../../../../actions/i18n";
import {getPresenceTeam} from "../../../../selectors/presence";
import DashboardAffiliate from "../../../MAC/Creator/DashboardAffiliate";

function MACCreatorDashboard({fetchAuthenticated, translate, isLoading, team}) {

    return (
        <div className="mac-wrapper">
            {!isLoading && (
                <div className="dashboard">

                    <ProfileStripe />
                    <DashboardAffiliate />
                    <DashboardBriefs />
                    <DashboardTrending
                        fetchAuthenticated={fetchAuthenticated}
                        translate={translate}
                        team={team}
                    />
                    <DashboardSpotlight
                        fetchAuthenticated={fetchAuthenticated}
                        translate={translate}
                        team={team}
                    />
                    <DashboardCommunity />

                </div>
            )}
        </div>
    )
}

export default connect(
    function (state, props) {
        return {
            isLoading: !state.i18n.didFetch,
            team: getPresenceTeam(state, props)
        };
    },
    function (dispatch) {
        return bindActionCreators({
            fetchAuthenticated: fetchAuthenticated,
            fetchPresence: fetchPresence,
            fetchBadges: fetchBadges,
            translate: translate
        }, dispatch);
    }
)(MACCreatorDashboard);