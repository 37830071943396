import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableWrapper from "../Table/Wrapper";
import {getAvailableMetadata} from "../../selectors/queries";
import {
    CHANNEL_STATUSES,
    formatCent,
    formatDollar,
    formatNumber,
    formatNumberK,
    getImageUrl,
    isReachVerified,
    limitChars,
    makeCellKey,
    makeMomentFromDate,
    preventDefaultIfPossible
} from "../../utilities";
import BasicCell from "../Table/Cell/Basic";
import {socialHandleToUrl, socialIconForType, socialNameForType} from "../../utilities/social";
import StarRating from "../Common/StarRating";
import LinkCell from "../Table/Cell/Link";
import _get from 'lodash/get';
import _findIndex from 'lodash/findIndex';
import _find from 'lodash/find';
import GenericProfileExpando from "../Common/GenericProfileExpando";
import GenericProfileDetail from "../Common/DetailView/GenericProfileDetail";
import Checkbox from "../Common/Form/Checkbox";
import {toggleRowSelectedWithMultiSelect} from "../../utilities/table";
import GenericActionsButton from "../Common/GenericActionsButton";
import * as URI from 'urijs';
import UserDetail from "../../containers/User/Detail";
import Toolbox from "../Common/Toolbox";
import ListFiltersToolbox from "./ListFiltersToolbox";
import InviteEntriesFromList from "./InviteEntriesFromList";
import Tooltip from "../Common/Tooltip";
import GenericProfileMiniProfile from "./GenericProfileMiniProfile";
import SettingsForm from "./SettingsForm";
import InvitationsTable from "./InvitationsTable";
import AddEntriesToList from "./AddEntriesToList";
import ActivateUsersToolbox from "../Campaign/ActivateUsersToolbox";
import Import from "./Import";
import NotesView from "../Common/NotesView";
import QuickFiltersButton from "./QuickFiltersButton";
import GenericProfilePerformanceThermometer from "../Common/GenericProfilePerformanceThermometer";
import ColumnSelectToolbox from "../Common/ColumnSelectToolbox";
import ActivationsHoverView from "../../containers/User/Pieces/ActivationsHoverView";
import ScrollingImageRow from "../Common/ScrollingImageRow";
import Wizard from "../User/Wizard/Wizard";
import ConnectedEmailCompose from '../Email/Compose';
import Alert from "../Common/Alert";
import Button from "../Common/Form/Button";
import UserChannelStatusSelect from "../User/Pieces/UserChannelStatusSelect";
import EntrySettingsForm from "./EntrySettingsForm";
import AddUsersToTeamToolbox from "./AddUsersToTeamToolbox";
import CopyEntriesToOtherLists from "./CopyEntriesToOtherLists";
import PDFExporter from "../User/PDFExporter";
import QuickEditUserForm from "../User/QuickEditUserForm";
import EditableTagCell from "./EditableTagCell";

export default class ViewListTable extends Component {

    static propTypes = {
        listId: PropTypes.number,
        list: PropTypes.object,
        entries: PropTypes.array,
        teams: PropTypes.array,
        query: PropTypes.object,
        onRefresh: PropTypes.func,
        onSortChange: PropTypes.func,
        onPageChange: PropTypes.func,
        onFilterChange: PropTypes.func,
        title: PropTypes.string,
        onMount: PropTypes.func,
        addNote: PropTypes.func,
        removeNote: PropTypes.func,
        addRating: PropTypes.func,
        addToOtherList: PropTypes.func,
        copyEntriesToLists: PropTypes.func,
        fetchTotals: PropTypes.func,
        createListInteraction: PropTypes.func,
        fetchEntry: PropTypes.func,
        renderFilters: PropTypes.func,
        renderQuickFilters: PropTypes.func,
        sortKeyMapping: PropTypes.object,
        getTotalForField: PropTypes.func,
        blockUser: PropTypes.func,
        setUserChannelStatus: PropTypes.func,
        isPublic: PropTypes.bool,
        // Will add a switch, useful for popup toolboxes
        onClose: PropTypes.func,
        // If given a campaign id, will replace buttons with an activate button
        useActivateButton: PropTypes.number,
        onActivatedUsers: PropTypes.func,
        channel: PropTypes.object,
        showBetaAlert: PropTypes.bool,
        useLazyLoad: PropTypes.bool,
        showTeamTabs: PropTypes.bool,
        fetchListSettings: PropTypes.func,
        banner: PropTypes.node,
        viewAsCards: PropTypes.bool,
        cardRenderer: PropTypes.func,
        onClickPrintToPdf: PropTypes.func,
        fetchListUserIds: PropTypes.func,
        fetchAllIds: PropTypes.func,
    };

    static defaultProps = {
        title: "Creators Found",
        isPublic: false,
        showBetaAlert: true,
        useLazyLoad: true,
        showTeamTabs: false,
        viewAsCards: false
    };

    state = {
        isExpanded: !!this.props.isPublic,
        currentEntryId: null,
        currentEntryTab: null,
        selectAll: false,
        selectEverything: false,
        isFetchingAllIds: false,
        selectedIds: {},
        allIds: [], // where we store results of fetchAllIds
        lastSelectedIndex: null,
        isRunningInteraction: false,
        initialFilters: this.props.query.filters,

        totals: null,
        isFetchingTotals: false,
        didFetchTotals: false,

        currentPopup: null,
        currentHover: {},
        showFilters: false,
        updatingEntries: {},
        showActivationsHover: null,
        showEditEmail: null,

        customColumns: [],
        expandedIds: {},
        tab: null,

        isFetchingSettings: false,
        settings: {},

        // For drag/drop in shared lists, to avoid indexing lag.
        ordinalityOverrides: {},

        // For exporting private/internal lists
        exportIds: []
    };

    /**
     * These two parameters are used when a custom filter box (ie user filter box) is in play and changes need to be debounced
     */
    _temporaryFilters = null;
    _filtersDebouncer = null;

    componentDidMount() {
        if (this.props.onMount) {
            this.props.onMount();
        }

        this.fetchSettings();
        this.handleRefresh();
        this.loadCustomColumns();
    }

    componentDidUpdate(prevProps, prevState) {
        this.detectUpdateRequiringRefresh(prevProps);
        // this.detectIsSharedList(prevProps, prevState);
    }

    detectIsSharedList(prevProps, prevState) {
        if (typeof prevState.settings.is_shared === 'undefined' && this.state.settings.is_shared === true) {
            console.log("Just realized that we're in a shared list. Update sort to ordinality.");
            this.handleSortChange({by: "ordinality", dir: 'asc'});
            setTimeout(() => {
                this.handleRefresh();
            }, 1000);
        }
    }

    fetchSettings() {
        if (!this.props.listId || !this.props.fetchListSettings || this.props.isPublic) {
            return;
        }
        this.setState({isFetchingSettings: true});
        this.props.fetchListSettings()
            .then(json => {
                this.setState({
                    settings: json.data,
                    isFetchingSettings: false
                });
            });
    }

    loadCustomColumns() {
        const rawSpec = window.localStorage.getItem('lists-custom-columns');
        if (!rawSpec) {
            return;
        }
        const json = JSON.parse(rawSpec);
        this.setState({
            customColumns: json,
        });
    }

    saveCustomColumns(columns) {
        const always = ['checkbox', 'profile.name.raw'];
        const merged = [...always, ...columns];
        this.setState({
            customColumns: merged,
        });
        window.localStorage.setItem('lists-custom-columns', JSON.stringify(merged));
    }

    isShowingGlobalPopup(key) {
        return this.state.currentPopup === key;
    }

    showGlobalPopup(key) {
        this.setState({currentPopup: key});
    }

    toggleGlobalPopup(key) {
        if (this.isShowingGlobalPopup(key)) {
            this.hidePopup();
        } else {
            this.showGlobalPopup(key);
        }
    }

    isShowingEntryPopup(key) {
        if (typeof this.state.currentPopup !== 'object') {
            return false;
        }

        if (this.state.currentPopup && this.state.currentPopup.type && this.state.currentPopup.id) {
            return this.state.currentPopup.type === key;
        }

        return false;
    }

    isShowingEntryPopupFor(key, entryId) {
        if (!this.isShowingEntryPopup(key)) {
            return false;
        }

        const entry = this.getEntryPopupEntry();
        return entry.id === entryId;
    }

    showEntryPopup(key, entryId) {
        this.setState({currentPopup: {type: key, id: entryId}});
    }

    toggleEntryPopup(key, entryId) {
        const isShowing = this.isShowingEntryPopupFor(key, entryId);
        if (isShowing) {
            this.hidePopup();
        } else {
            this.showEntryPopup(key, entryId);
        }
    }

    toggleImageScrollbar(id) {
        const isExpanded = this.state.expandedIds[id] === 'images';

        if (isExpanded) {
            let newExpandedIds = {...this.state.expandedIds};
            delete newExpandedIds[id];
            return this.setState({
                expandedIds: newExpandedIds
            })
        } else {
            return this.setState({
                expandedIds: {
                    ...this.state.expandedIds,
                    [id]: 'images'
                }
            })
        }
    }

    hidePopup() {
        this.setState({currentPopup: null});
    }

    getEntryPopupEntry() {
        if (typeof this.state.currentPopup === 'object' && this.state.currentPopup.id) {
            return _find(this.props.entries, {id: this.state.currentPopup.id});
        }

        return null;
    }

    resetFilters(additional = {}, title = null) {
        const filters = {...this.state.initialFilters, ...additional};
        this.setState({title});
        return this.props.onFilterChange(filters, this.props.query);
    }

    fetchTotals() {
        if (!this.props.fetchTotals) {
            return;
        }

        this.setState({isFetchingTotals: true});
        this.props.fetchTotals(this.props.query.filters)
            .then(json => this.setState({totals: json.data, isFetchingTotals: false, didFetchTotals: true}));
    }

    getTotalFor(field) {
        if (!field) {
            return 0;
        }

        if (typeof this.props.getTotalForField === 'function') {
            return this.props.getTotalForField(field, this.state.totals);
        }

        return _get(this.state.totals, field, 0);
    }


    handleListInteraction(type, context) {
        if (!this.props.createListInteraction) {
            return;
        }

        this.setState({isRunningInteraction: true});
        return this.props.createListInteraction(type, context)
            .then(() => {
                return this.handleRefresh();
            })
            .then(() => this.setState({isRunningInteraction: false}));
    }

    markEntryUpdating(id, isUpdating) {
        this.setState((prev) => {
            return {...prev, updatingEntries: {...prev.updatingEntries, [id]: isUpdating}};
        });
    }

    isEntryUpdating(id) {
        return !!this.state.updatingEntries[id];
    }

    handleRatingChange(item, rating) {

        this.markEntryUpdating(item.id, true);
        return this.props.addRating(item.id, rating * 2)
            .then(() => this.props.fetchEntry(item.id))
            .then(() => this.markEntryUpdating(item.id, false));
    }

    renderRatingCell(row, column) {

        if (row.item.preview) return this.makeLoadingCell(row, column);
        const latestScore = row.item.latest_score;
        const scaledScore = latestScore ? latestScore : null;
        let content = <StarRating rating={scaledScore} onRatingChange={this.handleRatingChange.bind(this, row.item)} />;

        if (row.item.scores && row.item.scores.length > 0) {
            const scoreList = (
                <div className="fake-table">
                    {row.item.scores.map((record, index) => (
                        <div className={"fake-li " + ((index < row.item.scores.length - 1) ? 'bottom-border' : '')} key={record.id}>
                            <strong>{record.scorer.name}</strong> rated a {record.score} / 5
                        </div>
                    ))}
                </div>
            );
            content = <Tooltip html={scoreList}>{content}</Tooltip>;
        }

        return <BasicCell
            column={column}
            row={row}
            key={makeCellKey(row, column)}
            value={content}
        />

    }

    toggleSelectAll() {
        const selectAll = !this.state.selectAll;
        let selectedIds = {};

        if (selectAll) {
            this.props.entries.forEach(a => selectedIds[a.id] = true);
        }

        return this.setState({selectAll, selectedIds, selectEverything: false, lastSelectedIndex: null});
    }

    async toggleSelectEverything() {
        const selectEverything = !this.state.selectEverything;
        let selectedIds = [];
        let selectAll = false;
        let allIds = [];

        if (selectEverything) {
            selectAll = true;
            allIds = await this.fetchAllIds();
            allIds.forEach(item => selectedIds[item.id] = true);
        }

        this.setState({selectEverything, selectAll, selectedIds, allIds});
    }

    async fetchAllIds() {
        this.setState({isFetchingAllIds: true});
        const ids = await this.props.fetchAllIds(this.props.query);
        this.setState({isFetchingAllIds: false});
        return ids;
    }

    makeFacetCellSpec(facet) {
        return {
            key: `profile.facets.${facet}`,
            title: facet,
            width: 150,
            sortable: true,
            default: true,
            public: true,
            sticky: false,
            cell: (row, column) => this.renderFacetCell(facet, row, column)
        }
    }

    renderFacetCell(facet, row, column) {
        if (row.item.preview) return this.makeLoadingCell(row, column);
        const facetValue = _get(row.item.profile.facets, facet, 'N/A');
        return <BasicCell
            column={column}
            row={row}
            key={makeCellKey(row, column)}
            value={facetValue}
        />
    }

    makeSocialCellSpec(socialTypes, totalField) {
        const niceName = socialNameForType(socialTypes[0]);
        return {
            key: 'socials.'+socialTypes.join('-'),
            title: niceName,
            width: 200,
            sortable: false,
            default: true,
            public: true,
            sticky: false,
            cell: (row, column) => this.renderSocialCell(socialTypes, row, column),
            total: formatNumberK(this.getTotalFor(totalField)) + ' Followers'
        };
    }

    renderSocialCell(socialTypes, row, column) {
        if (row.item.preview) return this.makeLoadingCell(row, column);
        const iconClass = socialIconForType(socialTypes[0]) + ' accent-icon';
        const allSocials = row.item.profile.socials || [];
        const socials = allSocials.filter(s => socialTypes.indexOf(s.type) > -1);
        let value = 'N/A';

        if (socials.length > 0) {
            const social = socials[0];
            const handle = social.slug;
            const url = (social.url && social.url.length > 4 && social.url.substr(0, 4) === 'http') ? social.url : socialHandleToUrl(socialTypes[0], handle);
            const followers = social.followers_count;
            const engPct = social.avg_engagement_pct;
            const formattedFollowers = followers ? formatNumberK(followers)  : null;
            const formattedEngPct = engPct ? formatNumber(100*engPct, 1) + '%' : null;
            const iconStyle = !!social.broken ? {} : {color: '#6C0'};
            const icon = <i className={iconClass} style={iconStyle} />;
            let secondary = [];
            if (formattedFollowers) {
                secondary.push(formattedFollowers + ' reach');
            }
            if (formattedEngPct) {
                secondary.push(formattedEngPct + ' eng');
            }

            value = (
                <span>
                    {icon} <a href={url} target="_blank" rel="noreferrer" className="dark">{handle}</a>
                    {secondary.length > 0 ? <br /> : ''}
                    {secondary.length > 0 ? secondary.join(', ') : ''}
                </span>
            );

        }

        return <BasicCell
            column={column}
            row={row}
            key={makeCellKey(row, column)}
            classes={['ellipsis']}
            value={value}
        />

    }

    renderNameCell(row, column) {
        const isShowingQuickEdit = this.isShowingEntryPopupFor('QuickEditUser', row.item.id);
        const isShowingNotes = this.isShowingEntryPopupFor('AddNote', row.item.id);
        const {currentEntryId, currentEntryTab, expandedIds} = this.state;
        const isPublic = this.props.isPublic;

        return <BasicCell
            column={column}
            row={row}
            key={makeCellKey(row, column)}
            style={{zIndex: 10}}
            value={
                <GenericProfileMiniProfile
                    isPublic={isPublic}
                    onClickPrimary={(e) => {
                        preventDefaultIfPossible(e);
                        this.setState({currentEntryId: row.item.id})
                    }}
                    onClickActivations = {isPublic ? undefined : () => this.setState({
                        currentEntryId: row.item.id,
                        currentEntryTab: 'Campaigns'
                    })}
                    onClickQuickEdit={
                        !isPublic ? () => this.toggleEntryPopup('QuickEditUser', row.item.id) : undefined
                    }
                    onClickNotes={() => this.toggleEntryPopup('AddNote', row.item.id)}
                    onClickStats={isPublic ? undefined : () => {
                        this.setState({
                            currentEntryId: row.item.id,
                            currentEntryTab: 'Stats'
                        })
                    }}
                    onClickContent={isPublic ? undefined : () => {
                        this.setState({
                            currentEntryId: row.item.id,
                            currentEntryTab: 'Posts'
                        })
                    }}
                    onClickLists={() => {
                        this.setState({
                            currentEntryId: row.item.id,
                            currentEntryTab: 'Lists'
                        })
                    }}
                    onClickImages={isPublic ? undefined : () => this.toggleImageScrollbar(row.item.id)}
                    row={row}
                    showQuickEdit={isShowingQuickEdit}
                    showNotes={isShowingNotes}
                    showActivations={currentEntryId === row.item.id && currentEntryTab === 'Campaigns'}
                    showStats={currentEntryId === row.item.id && currentEntryTab === 'Stats'}
                    showContent={currentEntryId === row.item.id && currentEntryTab === 'Posts'}
                    showLists={currentEntryId === row.item.id && currentEntryTab === 'Lists'}
                    showImages={expandedIds[row.item.id] === 'images'}
                />
            }
        />
    }

    renderBlogCell(row, column) {
        if (row.item.preview) return this.makeLoadingCell(row, column);
        const {website, blogs = []} = row.item.profile;
        const verifiedBlog = (blogs || []).filter(blog => !!blog.is_verified)[0];
        let url, title, reach = false;
        let secondary = '';
        let iconStyle = {fontSize: 14};

        if (verifiedBlog) {
            url = verifiedBlog.url;
            title = verifiedBlog.name;
            iconStyle.color = '#6C0';
            reach = verifiedBlog.verified_reach || verifiedBlog.traffic;
        } else if (blogs.length > 0) {
            const blog = blogs[0];
            url = blog.url;
            title = blog.name;
            reach = blog.verified_reach || blog.traffic;
        } else if (website) {
            url = website;
            title = website;
        }

        if (!url) {
            return <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={"N/A"}/>;
        }

        if (!title || title.length < 2) {
            title = url.replace('http://', '').replace("https://", "");
        }

        if (!!reach) {
            secondary = <span><br/>
                <span style={{color: '#aaa'}}>{`${formatNumberK(reach)} reach`}</span>
            </span>;
        }

        return <LinkCell row={row} column={column} key={makeCellKey(row, column)}
                         href={url}
                         value={<span><i className={'v3 icon rss accent-icon'} style={iconStyle} /> {title} {secondary}</span>}
                         target={"_blank"}
                         classes={['ellipsis']}
        />;

    }

    renderChannelStatusCell(row, column) {
        if (row.item.preview) return this.makeLoadingCell(row, column);
        const entry = row.item;
        const status = row.item.channel_status || 0;

        let content;

        if (this.props.isPublic) {
            const statuses = {...CHANNEL_STATUSES};
            content = statuses[status] ? statuses[status] : 'N/A';
        } else {
            const onChange = ({value}) => {
                if (entry.collectable_type !== 'Tidal\\User') {
                    return;
                }

                const userId = entry.collectable_id;
                this.markEntryUpdating(entry.id, true);
                this.props.setUserChannelStatus(userId, value)
                    .then(() => {
                        this.props.fetchEntry(entry.id)
                            .then(() => this.markEntryUpdating(entry.id, false))
                    });
            };

            content = <UserChannelStatusSelect
                classes={'white'}
                style={{paddingLeft: 0, color: '#222'}}
                status={status}
                onChange={onChange}
            />;
        }

        return (
            <BasicCell
                column={column}
                row={row}
                key={makeCellKey(row, column)}
                value={content}
            />
        );

    }

    /**
     * @deprecated
     * @param row
     * @param column
     * @returns {*}
     */
    renderInvitationCell(row, column) {
        const invitations = row.item.invitations || [];
        const invitationCount = invitations.length;
        const activationIds = _get(row.item, 'channel_stats.activation_ids', []);

        let content = null;
        if (activationIds.length > 0) {
            content = 'Activated';
        } else if (row.item.is_user_owned) {
            content = 'Joined';
        } else if (invitationCount === 0) {
            content = 'Not Invited';
        } else if (invitationCount > 0) {
            content = 'Invited';
        }

        if (invitationCount > 0) {
            content = <a
                role="button"
                className="dark"
                onClick={() => this.showEntryPopup('EntryInvitations', row.item.id)}
            >{content}</a>
        }

        return (
            <BasicCell
                column={column}
                row={row}
                key={makeCellKey(row, column)}
                value={content}
            />
        );
    }

    renderNotesCell(row, column) {
        if (row.item.preview) return this.makeLoadingCell(row, column);
        const noteRecords = (row.item.notes || []).filter(note => !!note.note);
        const renderedNotes = noteRecords.map((note, index) => {
            let classNames = ['fake-li'];
            if (index < noteRecords.length - 1) {
                classNames.push('bottom-border');
            }
            const date = makeMomentFromDate(note.created_at);
            return (
                <div className={classNames.join(' ')} style={{textAlign: 'left'}}>
                    <strong>{note.created_by.name}</strong> <em>{date.format('MMM Do, YYYY')}</em>:<br/>{note.note}
                </div>
            )
        });
        const icon = <i className="v3 icon comments" />;
        let preview = <span>{icon} <em>No notes yet. Click to add.</em></span>;
        if (noteRecords.length > 0) {
            preview = noteRecords.slice(0, 3).map(noteRecord => <div><strong>{noteRecord.created_by.name}</strong>: {limitChars(noteRecord.note, 23)}</div>);
        }
        const displayValue = noteRecords.length > 0
            ? <Tooltip html={renderedNotes}><div>{preview}</div></Tooltip>
            : <div>{preview}</div>;

        return (
            <BasicCell
                row={row}
                column={column}
                key={makeCellKey(row, column)}
                value={displayValue}
                onClick={() => this.toggleEntryPopup('AddNote', row.item.id)}
                style={{cursor: 'pointer'}}
            />
        );
    }

    renderRateCell(row, column) {
        const {metadata = {}, reach, engagement_pct} = row.item.profile;
        const {actual_price_range, ratecard_price_range} = metadata;
        let isVerified = false;
        let priceRange = 'N/A';
        let tooltipTitle = 'No price data available.';
        let lowPrice;

        if (actual_price_range) {
            priceRange = actual_price_range;
            lowPrice = parseInt(actual_price_range.replace(/[,\$]/g, ''), 10);
            isVerified = true;
            tooltipTitle = 'Based on actual activation history.';
        } else if (ratecard_price_range) {
            priceRange = ratecard_price_range;
            lowPrice = parseInt(ratecard_price_range.replace(/[,\$]/g, '').split(' - ')[0], 10);
            isVerified = false;
            tooltipTitle = 'Estimate based on rate card rates.';
        }

        const avgEngagements = engagement_pct * reach;
        const costPerReach = reach ? lowPrice / reach : null;
        const costPerEngagement = avgEngagements ? lowPrice / avgEngagements : null;
        let secondary;

        if (costPerReach || costPerEngagement) {
            let secondaryPieces = [];
            if (costPerReach && reach && reach > 0) {
                secondaryPieces.push(formatDollar(1000 * costPerReach, 0) + ' CPM');
            }
            if (costPerEngagement && engagement_pct && engagement_pct > 0) {
                const cpe = parseFloat(costPerEngagement) >= 1 ? `${formatDollar(costPerEngagement, 2)} CPE` : `${formatCent(costPerEngagement)} CPE`;
                secondaryPieces.push(cpe);
            }
            secondary = <div className="v3 light h9" style={{color: '#BBB'}}>{secondaryPieces.join(', ')}</div>;
        }

        const icon = isVerified ? <i className="v3 icon success" /> : null;
        const primary = <div>{icon} {priceRange}</div>;
        const content = <Tooltip title={tooltipTitle}>{primary} {secondary}</Tooltip>;

        return this.makeBasicCell(row, column, content);
    }

    toggleRowSelected(row, event = null) {
        const newState = toggleRowSelectedWithMultiSelect(this.state, this.props.entries, row, event);
        this.setState(newState);
    }

    isRowSelected(row) {
        return !!this.state.selectedIds[row.item.id];
    }

    getSelectedIds() {
        return Object.keys(this.state.selectedIds)
            .filter(id => !!this.state.selectedIds[id]);
    }

    getSelectedEmailAddresses() {

        const filtered = this.getSelectedEntries().filter(entry => {
            return  !!((entry.profile || {}).email);
        });

        return filtered.map(entry => {
            return {
                name: entry.profile.name,
                email: entry.profile.email,
                type: entry.collectable_type === 'Tidal\\User' ? entry.collectable_type : 'email',
                id: entry.collectable_type === 'Tidal\\User' ? entry.collectable_id : null,
            }
        });
    }

    isRowExpanded(row, allowGlobal = true) {
        if (allowGlobal && this.state.isExpanded) {
            return true;
        }

        return !!this.state.expandedIds[row.item.id];
    }

    getSelectedEntries() {
        // Type coercion below (==) is intentional
        return this.getSelectedIds()
            .map(id => {
                // First, try finding in entries, which is fully loaded data set
                const exists = _find(this.props.entries, e => e.id == id)
                if (exists) {
                    return exists;
                }
                // Next, fallback to allIds
                const existsInAllIds = _find(this.state.allIds, e => e.id == id)
                if (existsInAllIds) {
                    return existsInAllIds;
                }
                return null;
            })
            .filter(e => !!e);
    }

    getSortKey(field) {
        if (!this.props.sortKeyMapping) {
            return field;
        }
        if (typeof this.props.sortKeyMapping[field] === 'undefined') {
            return field;
        }
        return this.props.sortKeyMapping[field];
    }

    getCustomColumns() {
        const channel = this.props.channel || {};
        const additionalColumns = channel.additional_user_columns || [];

        // column.selector or column.key
        return additionalColumns.map((column, index) => {

            const defaults = {
                key: `custom_${index}`,
                title: 'Custom Column',
                sortable: false,
                default: true,
                public: false,
                width: 200,
                sticky: false,
                cell: (row, column) => {
                    const value = _get(row.item, column.selector || column.key, null);
                    return this.makeBasicCell(row, column, value);
                }
            };

            return {
                ...defaults,
                ...column
            };
        });
    }

    getColumns() {
        const channel = this.props.channel || {};
        const showPaymentStuff = !channel.disable_payments;
        return [
            {
                key: 'checkbox',
                sortable: false,
                default: true,
                public: false,
                title: <Checkbox
                    checked={this.state.selectAll}
                    label={null}
                    onClick={this.toggleSelectAll.bind(this)} />,
                width: (!this.props.isPublic && !!this.state.settings.is_shared) ? 78 : 55,
                sticky: true,
                cell: (row, column) => {
                    const isUpdating = this.isEntryUpdating(row.item.id) || row.item.preview;
                    const isShared = (!this.props.isPublic && !!this.state.settings.is_shared);
                    const checkbox = (isUpdating)
                        ? <i className="v3 icon spinner" style={{marginRight: (isShared ? 15 : undefined)}} />
                        : <Checkbox
                            checked={row.isSelected}
                            label={null}
                            onClick={(e) => this.toggleRowSelected(row, e)}
                        />;
                    const cog = (isShared)
                        ? <a className="godzilla-table-cell-switch-wrapper"
                             role="button"
                             onClick={() => this.toggleEntryPopup('EntrySettings', row.item.id)}>
                            <Tooltip title="Customize this user in the public list.">
                                <i className="v3 icon cog" />
                            </Tooltip>
                        </a>
                        : '';
                    return <BasicCell
                        key={makeCellKey(row, column)}
                        row={row}
                        column={column}
                        value={
                            <span className="godzilla-table-cell-checkbox-menu-wrapper">
                                {checkbox}
                                {cog}
                            </span>
                        }
                    />
                },
            },
            {
                key: 'profile.name.raw',
                sortKey: this.getSortKey('profile.name.raw'),
                title: "Name",
                sortable: true,
                default: true,
                public: true,
                width: 360,
                sticky: true,
                stickyLeft: this.props.isPublic ? null : 55,
                cell: (row, column) => this.renderNameCell(row, column),
                search: [
                    {
                        type: 'text',
                        name: 'profile.name',
                        title: 'Name',
                        placeholder: 'eg John Smith'
                    },
                    {
                        type: 'text',
                        name: 'profile.email.raw',
                        title: 'Email',
                        placeholder: 'eg john@smith.com'
                    }
                ]
            },
            {
                key: 'notes',
                title: 'Notes',
                sortable: false,
                default: true,
                public: true,
                width: 250,
                cell: (row, column) => this.renderNotesCell(row, column)
            },
            {
                key: 'latest_score',
                sortKey: this.getSortKey('latest_score'),
                sortDir: 'desc',
                title: "Rating",
                sortable: true,
                default: true,
                public: true,
                width: 120,
                sticky: false,
                cell: (row, column) => this.renderRatingCell(row, column),
                total: (() => {
                    const avg = this.getTotalFor('avgs.latest_score');
                    if (!avg) {
                        return null;
                    }
                    return formatNumber(avg / 2, 1) + ' Avg Rating'
                })()
            },
            {
                key: 'profile.performance.score',
                sortKey: this.getSortKey('profile.performance.score'),
                sortDir: 'desc',
                title: 'Score',
                sortable: true,
                default: true,
                public: false,
                width: 120,
                sticky: false,
                cell: (row, column) => {
                    if (row.item.preview) {
                        return this.makeLoadingCell(row, column);
                    }
                    const metadata = _get(row.item, 'profile.metadata', {});
                    const isVerified = row.item.collectable_type === 'Tidal\\User' && !metadata.shadow;
                    const performance = _get(row.item, 'profile.performance', null);
                    if (!performance) {
                        return <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={'N/A'} />
                    }

                    return <BasicCell column={column} row={row} key={makeCellKey(row, column)} style={{cursor: 'pointer'}}
                                    value={<GenericProfilePerformanceThermometer
                                            results={performance.results}
                                            score={performance.score}
                                            isVerified={isVerified}
                                    />}
                                    onClick={() => this.setState({
                                        currentEntryId: row.item.id,
                                        currentEntryTab: 'Performance'
                                    })}
                    />;
                }
            },
            {
                key: 'channel_status',
                title: 'Status',
                default: true,
                public: false,
                sortable: false,
                width: 150,
                sticky: false,
                cell: (row, column) => this.renderChannelStatusCell(row, column),
            },
            showPaymentStuff ? {
                key: 'profile.metadata.ratecard_price_range',
                sortable: false,
                title: "Rate",
                width: 160,
                default: false,
                public: false,
                cell: (row, column) => this.renderRateCell(row, column),
            } : null,
            {
                key: 'profile.reach',
                sortKey: this.getSortKey('profile.reach'),
                title: "Reach",
                sortable: true,
                sortDir: 'desc',
                default: true,
                public: true,
                width: 120,
                sticky: false,
                cell: (row, column) => {
                    if (row.item.preview) return this.makeLoadingCell(row, column);
                    const {profile} = row.item;
                    const {reach, blogs} = profile;
                    const formattedReach = reach ? formatNumber(reach) : 'N/A';
                    const isVerified = isReachVerified(blogs);
                    const iconStyle = {fontSize: 12, color : reach && isVerified ? '#6c0' : null};
                    return <BasicCell
                        column={column}
                        row={row}
                        key={makeCellKey(row, column)}
                        value={
                            <div>
                                <div>
                                    <i className={"v3 icon bullhorn accent-icon"} style={iconStyle}/>&nbsp;
                                    {formattedReach}
                                </div>
                                <div className="v3 light h9 " style={{color: "#BBB"}}>{row.item.profile.tier_name}</div>
                            </div>
                        }
                    />
                },
                total: formatNumberK(this.getTotalFor('sums.profile.reach')) + ' Reach'
            },
            {
                key: 'profile.engagement_pct',
                sortKey: this.getSortKey('profile.engagement_pct'),
                title: "Eng. Pct",
                sortable: true,
                sortDir: 'desc',
                default: true,
                public: true,
                width: 120,
                sticky: false,
                cell: (row, column) => {
                    if (row.item.preview) return this.makeLoadingCell(row, column);
                    const {engagement_pct} = row.item.profile;
                    const formattedEngPct = engagement_pct ? formatNumber(100 * engagement_pct, 1) + '%' : 'N/A';
                    return <BasicCell
                        column={column}
                        row={row}
                        key={makeCellKey(row, column)}
                        value={
                            <span>
                                <i className={"v3 icon comments accent-icon"} style={{fontSize: 12}}/>&nbsp;
                                {formattedEngPct}
                            </span>
                        }
                    />
                },
                total: (() => {
                    const avg = this.getTotalFor('avgs.profile.engagement_pct');
                    if (!avg) {
                        return null;
                    }
                    const formatted = formatNumber(100 * avg, 1) + '%';
                    return `Avg ${formatted}`;
                })()
            },
            ...this.getCustomColumns(),
            this.makeSocialCellSpec(['Tidal\\Social\\Instagram', 'Tidal\\Social\\InstagramBusiness'], 'sums.instagram_followers'),
            this.makeSocialCellSpec(['Tidal\\Social\\TikTok'] ),
            {
                key: 'profile.website',
                sortKey: this.getSortKey('profile.website'),
                title: "Website",
                sortable: true,
                default: true,
                public: true,
                width: 250,
                sticky: false,
                cell: (row, column) => this.renderBlogCell(row, column),
                total: formatNumber(this.getTotalFor('exists.profile.website')) + ' Websites'
            },
            {
                key: 'channel_stats.emv',
                sortable: true,
                sortKey: (this.props.list ? 'profile.metadata.' : '') + `channel_stats.${this.props.channel.mongo_id}.emv`,
                sortDir: 'desc',
                title: 'EMV',
                width: 120,
                default: true,
                public: false,
                cell: (row, column) => {
                    const {channel_stats} = row.item;
                    const emv = channel_stats ? channel_stats.emv : null;
                    const formattedEmv = emv ? '$' + formatNumber(emv, 2) : '-';
                    return <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={formattedEmv} />
                }
            },
            {
                key: 'channel_stats.total_engagements',
                sortable: true,
                sortKey: (this.props.list ? 'profile.metadata.' : '') + `channel_stats.${this.props.channel.mongo_id}.total_engagements`,
                sortDir: 'desc',
                title: 'Engagements',
                width: 140,
                default: true,
                public: false,
                cell: (row, column) => {
                    const {channel_stats} = row.item;
                    const value = channel_stats ? channel_stats.total_engagements : null;
                    const formatted = value ? formatNumber(value) : '-';
                    return <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={formatted} />
                }
            },
            {
                key: 'activation_ids',
                sortable: false,
                title: 'Campaigns',
                width: 110,
                default: true,
                public: false,
                cell: (row, column) => {
                    let hoverClasses = ['user-tablev2-campaigns-hover'];
                    const {currentHover} = this.state;
                    const activationIds = _get(row.item, 'channel_stats.activation_ids', []);
                    const hasActivations = !!activationIds.length;
                    const numActivations = hasActivations ? formatNumber(activationIds.length) : ' - ';
                    const style = {color: '#66cc00', cursor: 'pointer'};
                    if (row.index <= 2) {
                        hoverClasses.push('upper-row');
                    } else if (row.index >= (this.props.entries.length - 3)) {
                        hoverClasses.push('lower-row');
                    }
                    const isActive = currentHover.id === row.item.id && currentHover.type === 'EntryActivations';
                    const icon = hasActivations
                        ? <i className="fa fa-plus-square" style={style}/>
                        : null;
                    const onClick = hasActivations ? () => this.setState({
                        currentEntryId: row.item.id,
                        currentEntryTab: 'Campaigns'
                    }) : undefined;
                    const onMouseEnter = hasActivations
                        ? () => this.setState({
                            currentHover: {
                                id: row.item.id,
                                type: 'EntryActivations'
                            }
                        })
                        : undefined;
                    const onMouseLeave = hasActivations
                        ? () => this.setState({currentHover: {}})
                        : undefined;
                    const renderHoverView = isActive
                        ? <ActivationsHoverView showClickForMore={false} userId={row.item.collectable_id} classes={hoverClasses}/>
                        : null;
                    const content = <>
                        {renderHoverView}
                        <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>{icon} {numActivations}</div>
                    </>;

                    return <BasicCell row={row} column={column} value={content} style={{position: 'relative'}}
                                      key={makeCellKey(row, column)} onClick={onClick}/>;
                },
            },
            // {
            //     key: 'invitation_status',
            //     title: 'Status',
            //     default: false,
            //     sortable: false,
            //     width: 150,
            //     sticky: false,
            //     cell: (row, column) => this.renderInvitationCell(row, column)
            // },
            {
              key: 'profile.images',
              title: 'Images',
              default: true,
              public: false,
              sortable: false,
              width: 150,
              cell: (row, column) => {
                  if (row.item.preview) return this.makeLoadingCell(row, column);
                  const {profile, id} = row.item;
                  const numImages = (((profile || {}).images) || []).length;
                  const isActive = this.state.expandedIds[id] === 'images';
                  const style = {marginRight: 5, cursor: 'pointer', color: isActive ? '#999' : '#ccc'};
                  const content = numImages > 0
                      ? <div onClick={() => this.toggleImageScrollbar(id)}>
                          <i className='fa fa-camera' style={style}/>{numImages}
                        </div>
                      : ' - ';

                  return <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={content}/>
              }
            },
            {
                key: 'num_posts',
                title: 'Posts',
                default: true,
                public: false,
                sortable: false,
                width: 150,
                cell: (row, column) => {
                    if (row.item.preview) return this.makeLoadingCell(row, column);
                    const {currentEntryId, currentEntryTab} = this.state;
                    const numPosts = _get(row.item, 'channel_stats.num_posts', 0);
                    const isActive = currentEntryId === row.item.id && currentEntryTab === 'Posts';
                    const style = {marginRight: 3, cursor: 'pointer', color: isActive ? '#999' : '#ccc'};
                    const onClick = () => this.setState({
                        currentEntryId: row.item.id,
                        currentEntryTab: 'Posts'
                    });
                    const content = numPosts > 0
                        ? <div onClick={onClick}>
                            <i className='v3 icon post' style={style}/>{numPosts}
                        </div>
                        : ' - ';

                    return <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={content}/>
                }
            },
            this.makeSocialCellSpec(['Tidal\\Social\\Twitter'], 'sums.twitter_followers'),
            /* this.makeSocialCellSpec(['Tidal\\Social\\Facebook'], 'sums.facebook_followers'), */
            this.makeSocialCellSpec(['Tidal\\Social\\FacebookPage'], 'sums.facebook-page_followers'),
            this.makeSocialCellSpec(['Tidal\\Social\\Pinterest'], 'sums.pinterest_followers'),
            this.makeSocialCellSpec(['Tidal\\Social\\Youtube'], 'sums.youtube_followers'),
            this.makeSocialCellSpec(['Tidal\\Social\\Snapchat'] ),
            this.makeFacetCellSpec('Category'),
            this.makeFacetCellSpec('Language'),
            {
                key: 'profile.location',
                sortKey: this.getSortKey('profile.location'),
                title: "Location",
                default: true,
                public: true,
                sortable: true,
                width: 200,
                cell: (row, column) => <BasicCell
                    column={column} row={row} key={makeCellKey(row, column)}
                    value={row.item.profile.location || 'N/A'}
                />
            },
            {
                key: 'profile.tags',
                title: 'Tags',
                default: true,
                public: true,
                sortable: false,
                width: 200,
                cell: (row, column) => this.makeTagsCell(row, column),
            },
            {
                key: 'profile.stats.mozrank',
                sortKey: this.getSortKey('profile.stats.mozrank') ? this.getSortKey('profile.stats.mozrank') : null,
                title: 'MozRank',
                default: true,
                public: true,
                sortable: !!this.getSortKey('profile.stats.mozrank'),
                width: 150,
                cell: (row, column) => {
                    if (row.item.preview) return this.makeLoadingCell(row, column);
                    const mozrank = _get(row.item, 'profile.stats.mozrank');
                    return this.makeBasicCell(row, column, mozrank ? formatNumber(mozrank, 1) : 'N/A');
                }
            },
            {
                key: 'profile.stats.domain_authority',
                title: 'Domain Authority',
                default: true,
                public: true,
                sortable: !!this.getSortKey('profile.stats.domain_authority'),
                width: 160,
                cell: (row, column) => {
                    if (row.item.preview) return this.makeLoadingCell(row, column);
                    const da = _get(row.item, 'profile.stats.domain_authority');
                    return this.makeBasicCell(row, column, da ? formatNumber() : 'N/A');
                }
            },
            {
                key: 'profile.metadata.fastpass',
                sortKey: this.getSortKey('profile.metadata.fastpass'),
                title: 'FastPass',
                default: true,
                public: false,
                sortable: true,
                width: 160,
                cell: (row, column) => this.makeBasicCell(row, column, _get(row.item, 'profile.metadata.fastpass') || 'N/A')
            },
            {
                key: 'created_at',
                title: "Added On",
                default: true,
                public: true,
                sortable: true,
                sortDir: 'desc',
                width: 150,
                sticky: false,
                cell: (row, column) => {
                    const date = makeMomentFromDate(row.item.created_at);
                    const addedBy = row.item.added_by;
                    const content = (
                        <div>
                            <div>{date.format('MMM Do, YYYY')}</div>
                            {addedBy && <div className="v3 light h9" style={{color: '#BBB'}}>By {addedBy.name}</div> }
                        </div>
                    );

                    return <BasicCell
                        column={column}
                        row={row}
                        key={makeCellKey(row, column)}
                        value={content}
                    />
                },
            },
            {
                key: 'last_login',
                title: "Last Login",
                default: true,
                public: true,
                sortable: false,
                sortDir: 'desc',
                width: 150,
                sticky: false,
                cell: (row, column) => {
                    let content = '-';
                    if (row.item && row.item.profile && row.item.profile.metadata && row.item.profile.metadata.last_login) {
                        const date = makeMomentFromDate(row.item.profile.metadata.last_login);
                        content = (
                            <div>
                                <div>{date.format('MMM Do, YYYY')}</div>
                            </div>
                        );

                    }

                    return <BasicCell
                        column={column}
                        row={row}
                        key={makeCellKey(row, column)}
                        value={content}
                    />
                },
            },
            {
                key: 'column-select',
                title: (
                    <div style={{textAlign: 'center'}}>
                        <Tooltip title="Customize Table Columns">
                            <a role="button" onClick={() => this.toggleGlobalPopup('CustomizeColumns')}>
                                <i className="v3 icon cog" style={{position: 'relative', top: 12, right: 'inherit'}}/>
                            </a>
                        </Tooltip>
                    </div>
                ),
                default: true,
                public: false,
                sortable: false,
                width: 60,
                cell: (row, column) => <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={''} />
            }
        ].filter(f => !!f);
    }

    makeTagsCell(row, column) {
        if (row.item.preview) return this.makeLoadingCell(row, column);
        const tags = row.item.profile.tags || [];

        // if collectable_type is Tidal\User, then the userId is collectable_id
        const userId = row.item.collectable_type === 'Tidal\\User' ? row.item.collectable_id : null;

        return this.makeBasicCell(row, column, <EditableTagCell tags={tags} userId={userId}
                                                                onSaved={(newVals) => {
                                                                    this.markEntryUpdating(row.item.id, true);
                                                                    this.props.fetchEntry(row.item.id)
                                                                        .then(() => this.markEntryUpdating(row.item.id, false));
                                                                }}
        />);

    }

    makeBasicCell(row, column, value) {
        if (row.item.preview) {
            return this.makeLoadingCell(row, column);
        }

        return (
            <BasicCell column={column} row={row} key={makeCellKey(row, column)} value={value} />
        );
    }

    getCurrentPageMeta() {
        const {query} = this.props;
        return getAvailableMetadata(query);
    }

    getTotalHits() {
        const meta = this.getCurrentPageMeta();
        if (!meta || !meta.hits) return null;
        return parseInt(meta.hits, 10);
    }

    renderHeaderIcon() {
        const hits = this.getTotalHits();

        if (hits) {
            return <span className="large badge">{formatNumber(hits)}</span>;
        }

        return null;

    }

    getMaxPages() {
        const hits = this.getTotalHits();
        if (!hits) return 1;
        return Math.ceil(hits / (this.props.query.limit || 10));
    }

    handleSortChange(sort) {
        this.handlePageChange(1);
        return this.props.onSortChange(sort, this.props.query);
    }

    handlePageChange(page) {
        window.scrollTo(0, 0);
        return this.props.onPageChange(page, this.props.query);
    }

    detectUpdateRequiringRefresh(prevProps) {
        if (this.props.query.isDirty && !prevProps.query.isDirty && !this.props.query.isFetching) {
            this.handleRefresh();
        }
    }

    handleRefresh() {
        return this.props.onRefresh(this.props.query)
            .then(() => this.fetchTotals());
    }

    shouldBlur() {
        const {entries, query} = this.props;
        if (query.isFetching && entries.length > 0) {
            return true;
        }
        return false;
    }

    getImageUrlsForExpander(row) {

        const overrides = ((row.item.settings || {}).custom_images || [])
            .map(img => getImageUrl(img))
            .filter(url => !!url);

        if (this.props.isPublic && overrides.length > 0) {
            return overrides;
        }

        const campaignImages = row.item.campaign_image_urls || [];
        const profileImages = (row.item.profile || {}).images || [];
        return [...profileImages, ...campaignImages];

    }

    renderExpander(row) {

        const isPublic = this.props.isPublic;
        const {profile, settings = {}} = row.item;
        const images = this.getImageUrlsForExpander(row);
        const isValid = profile.text || Object.keys(profile.facets || {}).length > 0 || (profile.images || []).length > 0;

        if (this.state.expandedIds[row.item.id] === 'images') {
            return <ScrollingImageRow urls={images}/>;
        }

        if (!isValid) {
            return <div className="generic-profile-table-expando" />;
        }

        // Profile expando with overrides for public list view
        // getImageUrlsForExpander already considers override images
        return (
            <GenericProfileExpando
                {...profile}
                editorial_notes={isPublic ? settings.custom_description || undefined : undefined}
                text={isPublic ? settings.custom_profile_text || profile.text : profile.text}
                images={images}
            />
        );
    }

    getSwitches() {

        return [
            (this.props.isPublic && this.props.onClickPrintToPdf) ? {
                key: 'printToPdf',
                name: 'printToPdf',
                icon: <i className="v3 icon download" />,
                tooltip: 'Print to PDF',
                isActive: false,
                onClick: () => this.props.onClickPrintToPdf()

            } : undefined,
            this.props.list && !this.props.isPublic ? {
                key: 'Settings',
                name: 'Settings',
                icon: <i className="v3 icon cog" style={{}} />,
                tooltip: "List settings.",
                isActive: this.isShowingGlobalPopup('Settings'),
                onClick: () => this.toggleGlobalPopup('Settings'),
            } : null,
            {
                key: 'expando',
                name: 'expando',
                icon: <i className="v3 icon address-card" />,
                tooltip: "Show expanded rows",
                isActive: this.state.isExpanded,
                onClick: () => this.setState({isExpanded: !this.state.isExpanded, expandedIds: {}})
            },
            this.props.list && !this.props.isPublic ? {
                key: 'AddToList',
                name: 'AddToList',
                icon: <i className="fas fa-user-plus" style={{marginRight: 5}} />,
                tooltip: "Add to this list",
                isActive: this.isShowingGlobalPopup('AddToList'),
                onClick: () => this.toggleGlobalPopup('AddToList'),
            } : null,
            !this.props.isPublic
                ? {
                    key: 'BurgerMenu',
                    name: 'BurgerMenu',
                    icon: <i className="v3 icon burger-menu" />,
                    tooltip: "Show actions and tools menu.",
                    isActive: this.isShowingGlobalPopup('BurgerMenu'),
                    onClick: () => this.toggleGlobalPopup('BurgerMenu'),
                } : null,
            {
                key: 'filters',
                name: 'filters',
                icon: <i className="v3 icon search" />,
                tooltip: "Show search filters",
                isActive: this.state.showFilters,
                onClick: () => this.setState({showFilters: !this.state.showFilters})
            },
            this.props.onClose
                ? {
                    key: 'close',
                    name: 'close',
                    icon: <i className="fas fa-times" />,
                    tooltip: 'Close this tool.',
                    onClick: this.props.onClose
                } : null

        ];
    }

    renderSettingsPopup() {
        if (!this.isShowingGlobalPopup('Settings')) {
            return null;
        }

        if (!this.props.list) {
            return null;
        }

        return (
            <Toolbox
                key={'list-settings-popup'}
                title="List Settings"
                onClose={() => this.setState({currentPopup: null})}
                content={
                    <SettingsForm
                        listId={this.props.listId}
                    />
                }
                style={{
                    position: 'absolute',
                    top: 60,
                    left: '50%',
                    marginLeft: -450,
                    width: 900
                }}
            />

        );
    }

    renderCurrentEntryPopup() {
        if (!this.state.currentEntryId) {
            return null;
        }

        const entryIndex = _findIndex(this.props.entries, {id: this.state.currentEntryId});

        if (entryIndex === -1) {
            return null;
        }

        const entry = this.props.entries[entryIndex];
        const {profile} = entry;
        const latestScore = entry.latest_score;
        const scaledScore = latestScore ? latestScore / 2 : null;

        // Pagination
        let onClickNext = undefined;
        let onClickPrev = undefined;

        if (entryIndex > 0) {
            onClickPrev = () => {
                const prevEntryIndex = entryIndex - 1;
                const prevEntry = this.props.entries[prevEntryIndex];
                this.setState({currentEntryId: prevEntry.id});
            };
        }

        if (entryIndex < this.props.entries.length - 1) {
            onClickNext = () => {
                const nextEntryIndex = entryIndex + 1;
                const nextEntry = this.props.entries[nextEntryIndex];
                this.setState({currentEntryId: nextEntry.id});
            };
        }

        let detail = null;

        if (entry.collectable_type === 'Tidal\\User' && !this.props.isPublic) {
            detail = <UserDetail
                userId={entry.collectable_id}
                handleCloseLightbox={() => this.setState({currentEntryId: null, currentEntryTab: null})}
                openNextUser={onClickNext}
                openPrevUser={onClickPrev}
                listContext={this.props.list}
                currentView={this.state.currentEntryTab}
            />;
        } else {

            detail = <GenericProfileDetail
                {...profile}
                socialdata={entry.socialdata || undefined}
                collectable_type={entry.collectable_type}
                tab={this.state.currentEntryTab}
                rating={scaledScore}
                onRatingChange={this.handleRatingChange.bind(this, entry)}
                onClose={() => this.setState({currentEntryId: null, currentEntryTab: null})}
                onClickPrev={onClickPrev}
                onClickNext={onClickNext}
            />;
        }

        return (
            <div
                className="generic-profile-wrapper"
                style={{
                    position: 'fixed',
                    width: 'calc(100% - 40px)',
                    height: 'calc(100% - 40px)',
                    top: 20,
                    left: 20,
                    zIndex: 100,
                    boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
                    overflowY: 'auto'
                }}
            >
                {detail}
            </div>
        );
    }

    renderInviteEntriesPopup() {
        if (!this.isShowingGlobalPopup('InviteEntries')) {
            return null;
        }

        if (!this.props.createListInteraction) {
            return null;
        }

        const selectedIds = this.getSelectedIds();
        const selectedEntries = selectedIds.map(id => _find(this.props.entries, {id: parseInt(id)}));


        return (
            <Toolbox
                key={'list-invite-entries-popup'}
                title={'Invite Selected Users'}
                style={{
                    top: 60,
                    left: '50%',
                    marginLeft: -515,
                    width: 1030,
                    height: 'calc(100% - 100px)',
                    position: 'fixed',
                    overflow: 'auto',
                }}
                onClose={() => this.hidePopup()}
                content={
                    <InviteEntriesFromList
                        isSubmitting={this.state.isRunningInteraction}
                        entries={selectedEntries}
                        onSubmit={ctx => this.handleListInteraction('InviteEntries', ctx)
                            .then(() => this.hidePopup())
                        }
                    />
                }
            />
        );

    }

    renderCreateEntriesPopup() {
        if (!this.isShowingGlobalPopup('AddToList')) {
            return null;
        }

        if (!this.props.createListInteraction) {
            return null;
        }

        const addUsersToList = this.props.list
            ? (objects) => {
                return this.handleListInteraction('AddToList', {objects})
                    .then(() => {
                        this.hidePopup();
                        this.handleRefresh();
                    })
            }
            : (objects) => {
                this.hidePopup();
                this.handleRefresh();
            };

        return (
            <Toolbox
                key={'list-create-entries-popup'}
                title={'Add User to List'}
                style={{top: 62, right: 10, width: 400}}
                onClose={() => this.setState({currentPopup: null})}
                content={
                    <Wizard
                        onCreatedUser={data => {
                            const objects = [{type: 'Tidal\\User', id: data.id}];
                            addUsersToList(objects);
                        }}
                        onClaimedUsers={userIds => {
                            const objects = userIds.map(id => ({type: 'Tidal\\User', id}));
                            addUsersToList(objects);
                        }}

                    />
                }
            />
        );
    }

    renderSendEmailPopup() {
        if (!this.isShowingGlobalPopup('SendEmail')) {
            return null;
        }

        return (
            <Toolbox
                key={'list-send-email-popup'}
                title={<><i className="fa fa-send"/> Compose</>}
                addlClasses={['list-send-email-popup']}
                onClose={() => this.setState({currentPopup: null})}
                content={<ConnectedEmailCompose
                    showTitle={false}
                    to={this.getSelectedEmailAddresses()}
                    onSent={() => this.setState({currentPopup: null})}
                />}
            />
        );
    }

    renderFiltersPopup() {
        if (!this.state.showFilters) {
            return null;
        }

        // Custom filter renderer to allow using User Filters.
        if (this.props.renderFilters) {
            const filters = {...this.props.query.filters, ...(this._temporaryFilters || {})};
            return this.props.renderFilters({
                showCampaignFilters: true,
                filters: {...filters},
                onFilterChange: (filters) => {

                    if (this._filtersDebouncer) {
                        clearTimeout(this._filtersDebouncer);
                    }

                    this._filtersDebouncer = setTimeout(() => {
                        this.resetFilters(filters);
                        this._filtersDebouncer = null;
                        this._temporaryFilters = null;
                    }, 2000);

                }
            });
        }

        return (
            <ListFiltersToolbox
                key={'list-filters-toolbox-popup'}
                filters={this.props.query.filters}
                onSubmit={(newFilters) => this.resetFilters(newFilters)}
                onReset={() => {
                    this.resetFilters({});
                    this.setState({showFilters: false});
                }}
                onClose={() => this.setState({showFilters: false})}
                style={{top: 60, right: 0}}
            />
        );
    }

    renderInvitationsPopup() {
        if (!this.isShowingEntryPopup('EntryInvitations')) {
            return null;
        }

        const entry = this.getEntryPopupEntry();

        return (
            <Toolbox
                title={'Invitations'}
                onClose={() => this.hidePopup()}
                content={
                    <InvitationsTable
                        invitations={entry.invitations}
                    />
                }

                style={{
                    position: 'fixed',
                    top: 100,
                    left: '50%',
                    marginLeft: -400,
                    width: 800
                }}
            />
        );
    }

    renderEntrySettingsPopup() {
        if (!this.isShowingEntryPopup('EntrySettings')) {
            return null;
        }

        const entry = this.getEntryPopupEntry();

        return (
            <Toolbox
                addlClasses={'toolbox-fixed toolbox-md'}
                title={'Custom Settings for ' + entry.profile.name}
                onClose={() => this.hidePopup()}
                content={<EntrySettingsForm
                    fetchEntry={this.props.fetchEntry}
                    createListInteraction={this.props.createListInteraction}
                    markEntryUpdating={this.markEntryUpdating.bind(this)}
                    entry={entry}
                />}
            />

        );
    }
    renderNotesPopup() {
        if (!this.isShowingEntryPopup('AddNote')) {
            return null;
        }

        const entry = this.getEntryPopupEntry();

        return (
            <Toolbox
                title={'Notes for ' + entry.profile.name}
                onClose={() => this.hidePopup()}
                content={
                    <NotesView
                        notes={entry.notes || []}
                        isSubmitting={this.isEntryUpdating(entry.id)}
                        onRemoveNote={this.props.removeNote ? (note) => {
                            this.markEntryUpdating(entry.id, true);
                            this.props.removeNote(entry.id, note.id)
                                .then(() => {
                                    this.props.fetchEntry(entry.id)
                                        .then(() => this.markEntryUpdating(entry.id, false));
                                });
                        } : undefined}
                        onSaveNote={(note) => {

                            this.markEntryUpdating(entry.id, true);
                            this.props.addNote(entry.id, note)
                                .then(() => {
                                    this.props.fetchEntry(entry.id)
                                        .then(() => this.markEntryUpdating(entry.id, false))
                                        .then(() => this.hidePopup());
                                });


                        }}
                    />
                }
                style={{
                    position: 'fixed',
                    top: 100,
                    left: '50%',
                    marginLeft: -200,
                    width: 400
                }}
            />
        );
    }

    renderQuickEditUserPopup() {
        if (!this.isShowingEntryPopup('QuickEditUser')) {
            return null;
        }

        const entry = this.getEntryPopupEntry();

        if (entry.collectable_type !== 'Tidal\\User') {
            return null;
        }

        return (
            <Toolbox
                title={'Quick Edit User'}
                onClose={() => this.hidePopup()}
                content={<QuickEditUserForm
                    userId={entry.collectable_id}
                    onSave={() => {
                        this.markEntryUpdating(entry.id, true);
                        this.props.fetchEntry(entry.id)
                            .then(() => this.markEntryUpdating(entry.id, false))
                            .then(() => this.hidePopup());
                    }}
                />}
                addlClasses={'toolbox-fixed toolbox-sm'}
            />
        );
    }


    renderImportPopup() {
        if (!this.isShowingGlobalPopup('Import')) {
            return null;
        }
        if (!this.props.createListInteraction) {
            return null;
        }


        return (
            <Toolbox
                onClose={() => this.hidePopup()}
                title={'Import from Excel'}
                style={{
                    position: 'fixed',
                    top: 40,
                    width: 1100,
                    left: '50%',
                    marginLeft: -550,
                    height: 'calc(100% - 80px)',
                    overflow: 'auto'
                }}
                content={<Import
                    onImportBatch={(objects) => {
                        // This is basically handleListInteraction but without the refresh
                        const type = 'AddToList';
                        const context = {objects, do_cleanup: false};

                        if (!this.props.createListInteraction) { return; }
                        this.setState({isRunningInteraction: true});
                        return this.props.createListInteraction(type, context)
                            .then(() => this.setState({isRunningInteraction: false}));
                    }}
                    onImported={() => {
                        setTimeout(() => {
                            this.handleRefresh();
                            this.hidePopup();
                        }, 3000);
                    }}
                />}
            />
        );
    }

    renderAddToTeamPopup() {
        if (!this.isShowingGlobalPopup('AddToTeam')) {
            return null;
        }
        return this.renderAddRemoveTeamPopup('add');
    }
    renderRemoveFromTeamPopup() {
        if (!this.isShowingGlobalPopup('RemoveFromTeam')) {
            return null;
        }
        return this.renderAddRemoveTeamPopup('remove');
    }
    renderAddRemoveTeamPopup(action) {

        const users = this.getSelectedEntries()
            .filter(entry => entry.collectable_type === 'Tidal\\User');
        const userIds = users.map(e => e.collectable_id);

        return (
            <AddUsersToTeamToolbox
                action={action}
                userIds={userIds}
                onClose={() => this.hidePopup()}
                onComplete={() => {
                    this.handleRefresh();
                    setTimeout(() => this.hidePopup(), 2000);
                }}
            />
        );
    }

    renderActivateUsersPopup() {

        if (!this.isShowingGlobalPopup('ActivateUsers')) {
            return null;
        }

        const users = this.getSelectedEntries()
            .filter(entry => entry.collectable_type === 'Tidal\\User');
        const userIds = users.map(e => e.collectable_id);

        const nonUsersCount = this.getSelectedEntries()
            .filter(entry => entry.collectable_type !== 'Tidal\\User')
            .length;


        return (
            <ActivateUsersToolbox
                skippedItems={nonUsersCount}
                userIds={userIds}
                onClose={() => this.hidePopup()}
                selectedCampaignId={this.props.useActivateButton}
                onActivated={() => {
                    this.handleRefresh();
                    this.hidePopup();
                    if (this.props.onActivatedUsers) {
                        this.props.onActivatedUsers();
                    }
                }}
                style={{
                    top: 62,
                    left: 15,
                    width: 500
                }}
            />
        )
    }

    renderCopyEntriesPopup() {
        if ((!this.isShowingGlobalPopup('CopyEntries')) || !this.props.copyEntriesToLists) {
            return null;
        }

        const entryIds = this.getSelectedIds();

        return (
            <Toolbox
                title={"Copy To Other Lists"}
                onClose={() => this.hidePopup()}
                content={
                    <CopyEntriesToOtherLists
                        isSubmitting={this.state.isRunningInteraction}
                        onClickSubmit={(listIds) => {
                            this.setState({isRunningInteraction: true});
                            this.props.copyEntriesToLists(entryIds, listIds)
                                .then(() => this.hidePopup())
                                .then(() => this.setState({isRunningInteraction: false}));
                        }}
                    />
                }
                style={{
                    position: 'fixed',
                    top: 62,
                    width: 400,
                    left: '50%',
                    marginLeft: -200
                }}
            />
        );
    }

    renderAddToOtherListPopup() {
        if ((!this.isShowingGlobalPopup('AddToOtherList')) || !this.props.addToOtherList) {
            return null;
        }

        const objects = this.getSelectedEntries()
            .map(entry => {
                // map just type and id
                return {
                    type: entry.collectable_type,
                    id: parseInt(entry.collectable_id, 10)
                };
            })
            .filter(item => !!item);

        return (
            <Toolbox
                title={"Add To List"}
                onClose={() => this.hidePopup()}
                content={
                    <AddEntriesToList
                        isSubmitting={this.state.isRunningInteraction}
                        onClickSubmit={(listId) => {
                            this.setState({isRunningInteraction: true});
                            this.props.addToOtherList(listId, objects)
                                .then(() => this.hidePopup())
                                .then(() => this.setState({isRunningInteraction: false}));
                        }}
                    />
                }
                style={{
                    position: 'fixed',
                    top: 62,
                    width: 400,
                    left: '50%',
                    marginLeft: -200
                }}
            />
        );
    }

    renderCustomizeColumnsPopup() {
        if (!this.isShowingGlobalPopup('CustomizeColumns')) {
            return null;
        }

        const columnSpec = this.getColumns().filter(column => {
            return ['checkbox', 'profile.name.raw', 'column-select'].indexOf(column.key) === -1;
        });

        return (
            <ColumnSelectToolbox
                visibleColumns={this.state.customColumns}
                columns={columnSpec}
                onClose={() => this.hidePopup()}
                style={{top: 62, right: 10}}
                onChange={this.saveCustomColumns.bind(this)}
            />
        );

    }

    renderPopups() {

        return [
            this.renderCurrentEntryPopup(),
            this.renderCreateEntriesPopup(),
            this.renderFiltersPopup(),
            this.renderInviteEntriesPopup(),
            this.renderSettingsPopup(),
            this.renderEntrySettingsPopup(),
            this.renderQuickEditUserPopup(),
            this.renderInvitationsPopup(),
            this.renderAddToOtherListPopup(),
            this.renderCopyEntriesPopup(),
            this.renderActivateUsersPopup(),
            this.renderImportPopup(),
            this.renderNotesPopup(),
            this.renderToolsPopup(),
            this.renderCustomizeColumnsPopup(),
            this.renderSendEmailPopup(),
            this.renderAddToTeamPopup(),
            this.renderRemoveFromTeamPopup(),
            this.renderSavePrivatePDFPopup()
        ].filter(popup => !!popup);

    }

    renderSavePrivatePDFPopup() {
        if (!this.state.exportIds || this.state.exportIds.length === 0) {
            return null;
        }

        return (
            <PDFExporter
                onComplete={() => this.setState({exportIds: []})}
                userIds={this.state.exportIds}
                settings={this.state.settings || {}}
            />
        )
    }

    renderButtons() {
        if (this.props.useActivateButton) {

            const selectedUsersCount = this.getSelectedEntries()
                .filter(entry => entry.collectable_type === 'Tidal\\User')
                .length;
            const isValid = selectedUsersCount > 0;

            return [
                <Button
                    content={'Activate Users' + (isValid ? ` (${selectedUsersCount})` : '')}
                    classes={['btn-primary', 'small', 'bold']}
                    onClick={isValid ? () => this.toggleGlobalPopup('ActivateUsers') : undefined}
                />

            ];
        }
        return [
            this.props.isPublic ? null : this.renderQuickFiltersButton(),
            this.props.isPublic ? null : this.renderActionsButton(),
        ];
    }

    renderQuickFiltersButton() {

        if (this.props.renderQuickFilters) {
            return this.props.renderQuickFilters({
                onClickFilter: ({filters}) => this.resetFilters(filters)
            });
        }

        return (
            <QuickFiltersButton
                listId={this.props.listId}
                onClickFilter={({filters}) => this.resetFilters(filters)}
            />
        );
    }

    renderToolsPopup() {
        if (!this.isShowingGlobalPopup('BurgerMenu')) {
            return null;
        }

        const hasListInteractions = !!this.props.createListInteraction;
        const hasList = !!this.props.list;
        const hasIdsFunc = !!this.props.fetchListUserIds;

        const downloadUrl = URI('/manage/do/widget/users/export');
        const defaultExportParams = {
            filters: JSON.stringify(this.props.query.filters),
            download: 1
        };
        const xlsxDownloadUrl = downloadUrl.query({...defaultExportParams, format: 'xlsx'}).toString();
        const birthdaySheetUrl = '/manage/do/widget/users/exportBirthdays';
        return (
            <Toolbox
                onClose={() => this.hidePopup()}
                title={"Tools"}
                style={{
                    top: 62,
                    right: 10
                }}
                content={
                    <div className="fake-table">


                        <div className="fake-li bottom-border">
                            <a role="button" onClick={() => this.showGlobalPopup('CustomizeColumns')}>Customize Columns</a>
                        </div>

                        {(!hasList && !this.props.isPublic) ? (
                            <div className="fake-li bottom-border">
                                {/*<a href="/manage/do/widget/tools/exportSocialHandles" target="_blank">Export Social Handles</a>*/}
                                <a href={xlsxDownloadUrl} target="_blank">Export to Excel</a>
                            </div>
                        ) : null}

                        {(!hasList && !this.props.isPublic) ? (
                            <div className="fake-li bottom-border">
                                <a href={birthdaySheetUrl} target="_blank">Download Birthday Sheet</a>
                            </div>
                        ) : null}

                        {
                            hasList
                            && <div className="fake-li bottom-border">
                                <a role="button" onClick={() => this.showGlobalPopup('Settings')}>Edit List Settings</a>
                            </div>
                        }
                        {
                            hasListInteractions
                            && <div className="fake-li bottom-border">
                                <a role="button" onClick={() => this.showGlobalPopup('Import')}>Import from Excel</a>
                            </div>
                        }
                        {
                            (hasIdsFunc && hasList) && <div className="fake-li bottom-border">
                                <a role="button" onClick={() => this.handleSavePrivateListAsPDF()}>Save as PDF</a>
                            </div>
                        }
                        {
                            hasListInteractions
                            && <div className="fake-li">
                                <a
                                    onClick={() => {
                                        window.open(`/${window.Gator.getDashboardSlot()}/do/widget/tools/exportlist?id=${this.props.listId}`)
                                    }}
                                    role="button"
                                >Export to Excel</a>
                            </div>
                        }
                        {
                            hasListInteractions
                            && (<div className="fake-li">
                                <a onClick={() => {
                                    this.handleListInteraction('SocialProfileCleanup', {});
                                }} role="button">{this.state.isRunningInteraction ? 'Working, please wait...' : 'Autofill Social Data'}</a>
                            </div>)
                        }
                    </div>
                }
            />

        );

    }

    async handleSavePrivateListAsPDF() {
        const userIds = await this.props.fetchListUserIds();
        this.hidePopup();
        this.setState({exportIds: userIds});
    }

    renderActionsButton() {
        const hasListInteractions = !!this.props.createListInteraction;
        const selectedIds = this.getSelectedIds();
        const selectedCount = selectedIds.length;
        const selectedUsersCount = this.getSelectedEntries()
            .filter(entry => entry.collectable_type === 'Tidal\\User')
            .length;
        let title = "Actions";
        if (this.state.isRunningInteraction) {
            title = <span><i className="v3 icon spinner" style={{marginRight: 0}} /> Actions</span>;
        }
        const hasTeams = this.props.teams && this.props.teams.length > 0;

        return (
            <GenericActionsButton
                content={title}
                key={'list-actions-btn'}
                classes={['btn-secondary']}
                actions={[
                    {
                        name: "ActivateUsers",
                        title: `Activate Users ${selectedCount ? '(' + selectedCount + ')' : ''}`,
                        isValid: () => selectedCount > 0,
                        onClick: () => this.showGlobalPopup('ActivateUsers')
                    },
                    // {
                    //     name: 'InviteEntries',
                    //     title: `Invite Selected ${selectedCount ? '(' + selectedCount + ')' : ''}`,
                    //     isValid: () => selectedCount > 0 && hasListInteractions,
                    //     onClick: () => this.showGlobalPopup('InviteEntries')
                    // },
                    {
                        name: 'RemoveFromList',
                        title: `Remove Selected ${selectedCount ? '(' + selectedCount + ')' : ''}`,
                        isValid: () => selectedCount > 0 && hasListInteractions,
                        onClick: () => this.handleListInteraction('RemoveFromList', {entry_ids: selectedIds})
                    },
                    {
                        name: 'AddToOtherList',
                        title: `Add Selected to List ${selectedCount ? '(' + selectedCount + ')' : ''}`,
                        isValid: () => selectedCount > 0 && this.props.addToOtherList,
                        onClick: () => this.showGlobalPopup('AddToOtherList')
                    },
                    {
                        name: 'CopyEntries',
                        title: `Copy Selected to Other List ${selectedCount ? '(' + selectedCount + ')' : ''}`,
                        isValid: () => selectedCount > 0 && this.props.copyEntriesToLists,
                        onClick: () => this.showGlobalPopup('CopyEntries')
                    },
                    {
                        name: 'AddToTeam',
                        title: `Add Selected to Team ${selectedCount ? '(' + selectedCount + ')' : ''}`,
                        isValid: () => selectedCount > 0 && hasTeams,
                        onClick: () => this.showGlobalPopup('AddToTeam')
                    },
                    {
                        name: 'RemoveFromTeam',
                        title: `Remove Selected from Team ${selectedCount ? '(' + selectedCount + ')' : ''}`,
                        isValid: () => selectedCount > 0 && hasTeams,
                        onClick: () => this.showGlobalPopup('RemoveFromTeam')
                    },
                    {
                        name: 'BlockUsers',
                        title: `Block Users ${selectedCount ? '(' + selectedCount + ')' : ''}`,
                        isValid: () => selectedCount > 0 && this.props.blockUser,
                        onClick: () => this.handleBlockUsers(),
                    },
                    {
                        name: 'SendEmail',
                        title: `Email Selected (${selectedCount})`,
                        isValid: () => selectedCount > 0,
                        onClick: () => this.showGlobalPopup('SendEmail')
                    },
                ]}
            />
        );
    }

    getVisibleColumns() {

        // Public lists are fixed, so break early if we're in public mode.
        const {isPublic} = this.props;

        if (isPublic) {
            return this.getColumns().filter(col => col.public).map(col => col.key);
        }

        // Otherwise, take a look at custom column settings.
        const customColumns = this.state.customColumns;
        const hasCustom = customColumns && customColumns.length > 0;
        if (hasCustom) {
            return [...customColumns, 'column-select'];
        }

        // If neither public nor has custom, show default columns.
        let cols = this.getColumns().filter(col => col.default).map(col => col.key);

        if (!isPublic) cols.unshift('checkbox');

        return cols;
    }

    async handleBlockUsers() {
        const entries = this.getSelectedEntries()
            .filter(entry => entry.collectable_type === 'Tidal\\User');

        this.setState({isRunningInteraction: true});
        for (const entry of entries) {
            this.markEntryUpdating(entry.id, true);
            await this.props.blockUser(entry.collectable_id, true);
            this.markEntryUpdating(entry.id, false);
        }
        this.setState({isRunningInteraction: false});
        this.handleRefresh();
    }

    renderBetaAlert() {
        if (!this.props.showBetaAlert) {
            return null;
        }

        let url = new URI(window.location.href);
        if (this.props.list) {
            url.path('/manage/do/page/lists/viewv1');
        } else {
            url.path('/manage/do/page/users/allv1');
        }
        const content = <span>Having trouble with this new interface? <a className="v3 bold" href={url.toString()}>Go back to the old version</a>.</span>;
        return (
            <Alert classes={['info']} content={content} />
        );
    }

    makeLoadingCell(row, column) {
        return (
            <BasicCell column={column} row={row} key={makeCellKey(row, column) + '-loading'} value={'...'} />
        );
    }

    handleRowDrop(dropResult) {
        const pageNo = parseInt(this.props.query.page || 1, 10);
        const pageLim = parseInt(this.props.query.limit || 10, 10);
        let newOrdinalities = {};

        if (typeof dropResult.targetPaginate === 'undefined') {
            const startingOrdinality = 1 + ((pageNo - 1) * pageLim);
            let idsAfter = this.props.entries.map(e => e.id);
            idsAfter.splice(dropResult.endIndex, 0, idsAfter.splice(dropResult.startIndex, 1)[0]);

            for (const index in idsAfter) {
                newOrdinalities[idsAfter[index]] = startingOrdinality + parseInt(index, 10);
            }
        } else {
            // Pagination
            if (dropResult.targetPaginate === 1) {
                newOrdinalities[dropResult.itemId] = 1 + (pageNo * pageLim);
            } else {
                newOrdinalities[dropResult.itemId] = ((pageNo-1) * pageLim);
            }
        }

        console.log(newOrdinalities);
        this.setState({ordinalityOverrides: newOrdinalities});
        this.handleListInteraction('UpdateOrdinalities', {ordinalities: newOrdinalities});
    }

    fetchAllEntries() {
        for (const item of this.props.entries) {
            this.markEntryUpdating(item.id, true);
            this.props.fetchEntry(item.id).then(() => this.markEntryUpdating(item.id, false));
        }
    }

    handleRowVisibilityChange({item, isVisible}) {

        if (!this.props.useLazyLoad) {
            return;
        }

        if (isVisible && item.summary && !this.isEntryUpdating(item.id)) {
            this.markEntryUpdating(item.id, true);
            this.props.fetchEntry(item.id)
                .then(() => this.markEntryUpdating(item.id, false));
        }

    }

    handleClickTab(value) {

        let newFilter = value;
        if (value === this.state.tab) {
            newFilter = null;
        }

        const filters = {
            ...this.props.query.filters,
            channel_status: newFilter
        };

        this.resetFilters(filters);
        this.setState({tab: newFilter});

    }

    getTabs() {

        // Render tabs for each team
        // Don't show if props.teams is empty
        // don't show if props.showTeamTabs is false
        // don't show if props.list is true
        // don't show if props.isPublic is true
        if (!this.props.teams || !this.props.teams.length || !this.props.showTeamTabs || this.props.list || this.props.isPublic) {
            return null;
        }

        return this.props.teams.map(team => {

            const filterTeamId = parseInt(this.props.query.filters.team, 10);
            const thisTeamId = parseInt(team.id, 10);
            return {
                title: team.name,
                isActive: filterTeamId === thisTeamId,
                onClick: () => {
                    // If the query already has the team filter, unset it
                    if (filterTeamId === thisTeamId) {
                        this.resetFilters({team: null});
                    } else {
                        // otherwise, set the team filter
                        this.resetFilters({team: thisTeamId});
                    }
                },
                key: team.name
            };

        });
    }

    getItems() {
        const items = this.props.entries;
        const query = this.props.query;
        const sort = query.sort || {};

        if (sort.by === 'ordinality' && sort.dir === 'asc') {
            return items.sort((a, b) => {
                const aOrd = typeof this.state.ordinalityOverrides[a.id] !== 'undefined' ? this.state.ordinalityOverrides[a.id] : a.ordinality;
                const bOrd = typeof this.state.ordinalityOverrides[b.id] !== 'undefined' ? this.state.ordinalityOverrides[b.id] : b.ordinality;
                return aOrd > bOrd ? 1 : -1;
            });
        }

        return items;
    }

    renderSelectAllBanner() {
        const currentSelected = this.getSelectedIds();
        const totalHits = this.getTotalHits();
        const isFetchingAllIds = this.state.isFetchingAllIds;
        const hasMultiplePages = this.getMaxPages() > 1;


        if (this.state.selectAll && !this.state.selectEverything && hasMultiplePages) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>Currently selecting {currentSelected.length} items on this page. <a className="v3 bold" onClick={this.toggleSelectEverything.bind(this)} role="button">Select all {totalHits} instead.</a> {isFetchingAllIds && <i className="v3 icon spinner" />}</span>}
                />
            );
        }

        if (this.state.selectEverything) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>Selected {currentSelected.length} items from all pages.</span>}
                />
            );

        }

        return null;
    }

    renderBanner() {
        if (this.state.selectAll || this.state.selectEverything) {
            return this.renderSelectAllBanner();
        }

        if (this.props.banner) {
            return this.props.banner;
        }

        return null;
    }

    render() {

        const isShared = (!this.props.isPublic && !!this.state.settings.is_shared);

        return (

            <div className="view-list-table-wrapper">
                <TableWrapper
                    items={this.getItems()}
                    columns={this.getColumns()}

                    title={this.props.title}
                    headerIcon={this.renderHeaderIcon()}
                    stickySortTabs={true}
                    showTotals={this.props.fetchTotals && this.state.didFetchTotals}

                    tabs={this.getTabs()}

                    switches={this.getSwitches()}
                    buttons={this.renderButtons()}

                    filters={this.props.query.filters || {}}
                    showFilters={false}

                    sort={this.props.query.sort}
                    onSortChange={this.handleSortChange.bind(this)}

                    showPagination={true}
                    page={this.props.query.page}
                    pages={this.getMaxPages()}
                    onPageChange={this.handlePageChange.bind(this)}

                    isRowExpanded={row => this.isRowExpanded(row)}
                    isRowSelected={row => this.isRowSelected(row)}
                    expander={this.renderExpander.bind(this)}

                    popups={this.renderPopups()}
                    blurry={this.shouldBlur()}

                    visibleColumns={this.getVisibleColumns()}
                    onRowVisibilityChange={this.handleRowVisibilityChange.bind(this)}

                    banner={this.renderBanner()}
                    viewAsCards={this.props.viewAsCards}
                    cardRenderer={this.props.cardRenderer}

                    useDragDrop={isShared && this.props.query.sort && this.props.query.sort.by === 'ordinality'}
                    onRowDrop={this.handleRowDrop.bind(this)}
                />
                {this.renderBetaAlert()}
            </div>

        );

    }
}
