import React, {useState, useEffect, useMemo} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../../../actions/auth";
import {getCampaigns} from "../../../../selectors/campaigns";
import {getCurrentChannel, getCurrentUser, getPresenceTeam} from "../../../../selectors/presence";
import {translate} from "../../../../actions/i18n";
import {Link} from "react-router-dom";
import TableWrapper from "../../../Table/Wrapper";
import BasicCell from "../../../Table/Cell/Basic";
import {formatDollar, formatNumber, makeCellKey} from "../../../../utilities";
import CheckboxCell from "../../../Table/Cell/Checkbox";
import Form from "../../../Common/Form/Form";
import MiniProfile from "../../../Common/MiniProfile";

function MACAffiliateSearch({fetchAuthenticated, campaigns, user, team, channel, translate}) {
    const [res, setRes] = useState('');
    const [keyword, setKeyword] = useState('');
    const [debouncer, setDebouncer] = useState(null);
    const [isFetching, setIsFetching] = useState(false);

    const backWord = translate('creator.words.back', 'Back');

    const fetchSearch = async (query={}) => {
        setIsFetching(true);
        try {
            const resp = await fetchAuthenticated(
                '/contribute/do/widget/dash/aff_proxy?path='
                    + encodeURIComponent('/productsearch/1.0')
                    + '&query=' + encodeURIComponent(JSON.stringify(query))
            );

            const rawXml = await resp.text();
            setRes(rawXml);

        } catch (e) {
            setRes(e.message);
        }
        setIsFetching(false);
    }

    useEffect(() => {
        fetchSearch();
    }, []);

    // now parse the response
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(res || '', "application/xml");
    console.log(xmlDoc);
    const totalMatchesTag = xmlDoc.getElementsByTagName('TotalMatches');
    const totalMatches = totalMatchesTag.length ? totalMatchesTag[0].textContent : 0;
    const totalPagesTag = xmlDoc.getElementsByTagName('TotalPages');
    const totalPages = totalPagesTag.length ? totalPagesTag[0].textContent : 0;
    const pageNumberTag = xmlDoc.getElementsByTagName('PageNumber');
    const pageNumber = pageNumberTag.length ? pageNumberTag[0].textContent : 0;
    const itemsTags = xmlDoc.getElementsByTagName('item');


    const items = useMemo(() => {
        return Array.from(itemsTags).map((itemTag) => {
            const item = {};
            for (let i = 0; i < itemTag.childNodes.length; i++) {
                const child = itemTag.childNodes[i];
                if (child.nodeType === 1) {
                    item[child.tagName] = child.textContent;
                }
            }
            return item;
        });
    }, [itemsTags]);

    /**
     * An item looks like this:
     * {
     *     "mid": "24775",
     *     "merchantname": "Clinique Online",
     *     "linkid": "24775810998107",
     *     "createdon": "2024-08-29T02:05:11.000Z",
     *     "sku": "SKU31806",
     *     "productname": "Clinique Even Better™ Makeup Broad Spectrum SPF 15, WN 120 Pecan - 1.0 oz/30 ml for Dry Combination and Combination Oily Skin",
     *     "category": "Health & BeautyPersonal Care~~Cosmetics~~Makeup~~Face Makeup > Foundations & Concealers",
     *     "price": "39",
     *     "saleprice": "39",
     *     "upccode": "",
     *     "description": "Dermatologist-developed liquid foundation with vitamin C. Visibly reduces dark spots in 12 weeks.* Dermatologist tested. Ophthalmologist tested. Safe for sensitive skin. Allergy tested. 100% fragrance free. Clinique Even Better & trade; Makeup Broad Spectrum SPF 15, WN 120 Pecan - 1.0 oz/30 ml for Dry Combination and Combination Oily Skin",
     *     "keywords": "",
     *     "linkurl": "https://click.linksynergy.com/link?id=XnusZEo64SQ&offerid=1506671.24775810998107&type=15&murl=https%3A%2F%2Fwww.clinique.com%2Fproduct%2F1599%2F5276%2Fmakeup%2Fface%2Ffoundations%2Feven-bettertm-makeup-broad-spectrum-spf-15%3Fshade%3DWN_120_Pecan",
     *     "imageurl": "https://www.clinique.com/media/export/cms/products/1200x1500/cl_sku_6MNY32_1200x1500_0.png"
     *   },
     */

    const simpleCell = (row, column) => {
        return ( <BasicCell value={row.item[column.key]} row={row} column={column} key={makeCellKey(row, column)} /> )
    }
    const columns = [
        {
            key: 'select',
            title: "",
            width: 50,
            default: true,
            cell: (row, column) => {
                return (
                    <CheckboxCell row={row} column={column} key={makeCellKey(row, column)} />
                )
            }
        },
        {
            key: 'miniprofile',
            title: "Product Name",
            width: 550,
            default: true,
            cell: (row, column) => {
                const primary = <a href={row.item['linkurl']} className="dark" target="_blank" rel="noopener noreferrer">{row.item['productname']}</a>;
                return (
                    <BasicCell
                        row={row} column={column} key={makeCellKey(row, column)}
                        value={<MiniProfile
                            imageUrl={row.item['imageurl']}
                            primary={primary}
                            secondary={row.item['merchantname']}
                            avatarSize={240}
                            square={true}
                        />}
                    />
                );
            }
        },
        {
            key: "price",
            title: "Price",
            width: 100,
            default: true,
            cell: (row, column) => {
                return (
                    <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                 value={formatDollar(row.item[column.key])}
                               />
                )
            }
        },
        {
            key: "description",
            title: "Description",
            width: 400,
            default: true,
            cell: simpleCell
        },
        {
            key: "category",
            title: "Category",
            width: 200,
            default: true,
            cell: simpleCell
        }
    ];

    const buildSearchQuery = ({page, keyword}) => {
        let out = {};
        if (page) {
            out.pagenumber = page;
        }
        if (keyword) {
            out.keyword = keyword;
        }
        return out;
    }

    const onPageChange = (page) => {
        fetchSearch(buildSearchQuery({page, keyword}))
            .then(() => {
                window.scrollTo(0, 0);
            });
    }

    useEffect(() => {
        // set debouncer
        if (debouncer) {
            clearTimeout(debouncer);
        }
        const newDebouncer = setTimeout(() => {
            fetchSearch(buildSearchQuery({page: 1, keyword}));
        }, 500);
        setDebouncer(newDebouncer);
    }, [keyword]);

    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                <div className="briefs-page-wrapper">

                    <div className="page-title-wrapper briefs-title-wrapper">
                        <h1 className="red-indent">Offer Search
                            <Link to="/contribute/do/page/dash/home" className="seeall">{backWord}<i className="v3 icon fa-arrow-left" /> </Link>
                        </h1>
                    </div>

                    <div className="briefs-table-wrapper">

                        <TableWrapper
                            banner={(
                                <div className={'affiliate-search-banner'}>
                                    <Form
                                        fields={[
                                            {
                                                type: 'text',
                                                name: 'keyword',
                                                placeholder: "Search for a product..."
                                            }
                                        ]}
                                        usePadding={false}
                                        values={{keyword}}
                                        onFieldChange={(name, value) => setKeyword(value)}
                                    />
                                </div>
                            )}
                            title={"Products on Offer"}
                            headerIcon={<span className="large badge">{formatNumber(totalMatches)}</span>}
                            items={items}
                            columns={columns}
                            page={pageNumber}
                            pages={totalPages}
                            onPageChange={onPageChange}
                            blurry={isFetching}
                        />

                    </div>

                </div>

            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    campaigns: getCampaigns(state, props),
    user: getCurrentUser(state, props),
    team: getPresenceTeam(state, props),
    channel: getCurrentChannel(state, props),
});
const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    translate: translate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACAffiliateSearch);