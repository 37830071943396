import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Form from '../../Common/Form/Form';
import Alert from '../../Common/Alert';
import {connect} from 'react-redux';
import {getTeams} from "../../../selectors/teams";
import {getCatalogs} from "../../../selectors/products";
import {fetchProductCatalogs} from "../../../actions/products";
import {fetchCampaignForms, fetchLists} from "../../../actions";
import {getCampaignForms, getCampaignsByDate} from "../../../selectors/campaigns";
import {fetchAuthenticated} from "../../../utilities";
import {getWorkflows} from "../../../selectors/workflows";
import {fetchWorkflowsIfNeeded} from "../../../actions";
import {getCurrentChannel} from "../../../selectors/presence";
import {createChannelInteraction} from "../../../actions/channel";
import {DEFAULT_REPORT_SPEC} from "../../Report/helpers";
import _set from 'lodash/set';
import _merge from "lodash/merge";
import {getLists} from "../../../selectors/lists";
import Tooltip from "../../Common/Tooltip";
import NavStacked from "../../Common/NavStacked";

const ALLOW_INTERACTIVE_BRIEFS = false;
// Settings that we force to be set for a MAC campaign.
// Content should auto_approve, and should set 1 other social post requirement
const FORCE_SETTINGS = {
    num_other_posts: 1,
    auto_approve_content: true,
};

class MACCampaignSettingsForm extends Component {

    static defaultProps = {
        style: {},
        activationFieldsOnly: false,
        isMobile: false,
    };

    static propTypes = {
        campaign: PropTypes.object.isRequired,
        campaigns: PropTypes.arrayOf(PropTypes.object),
        workflows: PropTypes.arrayOf(PropTypes.object),
        teams: PropTypes.array,
        lists: PropTypes.array,
        onSave: PropTypes.func.isRequired,
        style: PropTypes.object,
        catalogs: PropTypes.array,
        forms: PropTypes.array,
        fetchProductCatalogs: PropTypes.func,
        fetchForms: PropTypes.func,
        fetchLists: PropTypes.func,
        fetchWorkflows: PropTypes.func,
        fetchBriefs: PropTypes.func,
        activationFieldsOnly: PropTypes.bool,
        settings: PropTypes.object.isRequired,
        isMobile: PropTypes.bool,
    };

    constructor(props) {
        super(props);
        this.state = {
            showAddCatalog: false,
            updatingFields: {},
            updatedFields: {},
            errorFields: {},
            pendingUpdates: {},
            isUpdating: false,
            didUpdate: false,
            isFetchingTemplates: false,
            templates: [],
            briefs: null,
            view: 'basics'
        };
        this.toggleAddCatalog = this.toggleAddCatalog.bind(this);
    }

    componentDidMount() {
        // this.props.fetchProductCatalogs();
        this.props.fetchLists();
        // this.props.fetchForms(this.props.campaign.id);

        this.fetchBriefs();
        if (!this.props.activationFieldsOnly) {
            // this.props.fetchWorkflows();
            this.fetchTemplates();
        }
    }

    handleChangeSection(view) {
        this.setState({view});
    }

    isSectionActive(view) {
        return this.state.view === view;
    }


    fetchBriefs() {
        this.props.fetchBriefs()
            .then(resp => {
                this.setState({briefs: Object.values(resp.data.results)});
            })
    }

    fetchTemplates() {
        const defaultTemplate = {
            id: 'default',
            name: 'Default Template',
            template: [...DEFAULT_REPORT_SPEC],
            settings: {
                readOnly: true,
            }
        };

        this.setState({isFetchingTemplates: true});
        return this.props.fetchReportTemplates()
            .then(json => {
                this.setState({
                    isFetchingTemplates: false,
                    templates: [defaultTemplate, ...Object.values(json.data.results)]
                });
            })
    }

    handleUploadAttachment(event) {

        const slot = window.Gator.getDashboardSlot();
        const data = new FormData();
        data.append('attachment', event.target.files[0]);

        const url = `/${slot}/api/email/-1/attachment`;

        return fetchAuthenticated(url, {
            method: 'post',
            body: data,
        })
            .then(resp => resp.json())
            .then(json => json.data);

    }

    getSettingsObject() {
        const campaign = this.props.campaign || {};
        const settings = {...this.props.settings || {}};
        const native = {
            name: campaign.name,
            slug: campaign.slug,
            description: campaign.description,
            start: campaign.start,
            end: campaign.end,
            image_id: campaign.image_id,
            billable_team_id: campaign.billable_team_id,
            tags: campaign.tags,
            archived: !!campaign.archived,
            parent_id: campaign.parent_id
        };

        const pending = {...this.state.pendingUpdates};

        const merged = _merge({}, settings, native, pending);

        if (merged.hashtag && typeof merged.hashtag === 'string') {
            merged.hashtag = merged.hashtag.split(',').map(tag => tag.trim());
            if (!merged.hashtag.length) {
                merged.hashtag = '';
            }
        }

        return merged;
    }

    markFieldUpdating(name, isUpdating = true) {
        this.setState({
            updatingFields: {
                ...this.state.updatingFields,
                [name]: isUpdating
            }
        });
    }

    markFieldUpdated(name, didUpdate = true) {
        this.setState({
            updatedFields: {
                ...this.state.updatedFields,
                [name]: didUpdate
            }
        });
    }

    markFieldError(name, error) {
        this.setState({
            errorFields: {
                ...this.state.errorFields,
                [name]: error
            }
        });

    }

    toggleAddCatalog() {
        this.setState({
            showAddCatalog: !this.state.showAddCatalog
        })
    }

    commitUpdates() {

        const pending = {
            ...this.state.pendingUpdates,
            ...FORCE_SETTINGS
        };

        this.setState({isUpdating: true});
        let promise = this.props.onSave(pending);

        promise.then(() => {
            const nowPending = {...this.state.pendingUpdates};
            Object.keys(pending)
                .forEach(name => {
                    this.markFieldUpdating(name, false);
                    this.markFieldUpdated(name, true);
                    delete(nowPending[name]);
                });

            this.setState({pendingUpdates: nowPending, isUpdating: false, didUpdate: true});

            setTimeout(() => {
                Object.keys(pending)
                    .forEach(name => this.markFieldUpdated(name, false));
                this.setState({didUpdate: false});
            }, 3000);
        });

    }

    handleFieldChange(name, value) {

        this.markFieldUpdating(name, true);

        if (name.indexOf('share_report_vars.') === 0 && value === '') {
            value = null;
        }

        if (name === 'hashtag' && typeof value !== 'string') {
            value = value.join(', ');
        }

        let newPendingUpdates = _set(this.state.pendingUpdates, name, value);

        this.setState({
            pendingUpdates: newPendingUpdates
        });

    }

    isFieldUpdating(name) {
        return !!this.state.updatingFields[name];
    }

    isFieldUpdated(name) {
        return !!this.state.updatedFields[name];
    }

    getUpdatingFields() {
        return Object.keys(this.state.updatingFields)
            .filter(name => this.isFieldUpdating(name));
    }

    getUpdatedFields() {
        return Object.keys(this.state.updatedFields)
            .filter(name => this.isFieldUpdated(name));
    }

    renderNav() {
        return (
                <NavStacked
                    isHorizontal={this.props.isMobile}
                    items={[
                        {
                            title: "Basic Settings",
                            onClick: this.handleChangeSection.bind(this, 'basics'),
                            isActive: this.isSectionActive('basics'),
                        },
                        {
                            title: "Invite Copy",
                            onClick: this.handleChangeSection.bind(this, 'copy'),
                            isActive: this.isSectionActive('copy'),
                        },
                        {
                            title: "Team and Subcampaign",
                            onClick: this.handleChangeSection.bind(this, 'organization'),
                            isActive: this.isSectionActive('organization'),
                        },
                    ]}
                />
        );
    }

    renderActiveForm() {
        switch (this.state.view) {

            case 'organization':
                return this.renderOrganizationForm();
            case 'copy':
                return this.renderCopyForm();
            case 'basics':
            default:
                return this.renderBasicsForm();
        }
    }

    renderOrganizationForm() {

        const settings = this.getSettingsObject();
        const showCampaignFields = !this.props.activationFieldsOnly;
        const hashtagsToMonitorTooltip = <Tooltip title={"Posts that include ANY of these hashtags will be pulled into the campaign. Hit TAB or RETURN/ENTER after typing a hashtag to add it."}><i className="v3 icon help" /></Tooltip>
        const hashtagsSourceTooltip = <Tooltip title={"Monitor content only from users in the selected group. Avoid leaving this on 'All Users'."}><i className="v3 icon help" /></Tooltip>

        return <Form
            updatedFields={this.getUpdatedFields()}
            onFieldChange={this.handleFieldChange.bind(this)}
            values={settings}
            fields={[
                this.props.teams.length > 0 ? {
                    type: 'select',
                    name: 'billable_team_id',
                    title: "Choose Your Team",
                    choices: [
                        {text: 'No Team / Global Brief', value: 0},
                        ...this.props.teams.map(team => ({text: team.name, value: team.id}))
                    ]
                } : null,
                showCampaignFields ? {
                    type: 'select',
                    title: 'Make this a Subcampaign Of',
                    name: 'parent_id',
                    choices: [
                        {value: null, text: '- Standalone Campaign -'},
                        ...this.props.campaigns
                            .filter(c => c.id !== (this.props.campaign || {}).id)
                            .map(c => ({value: c.id, text: c.name}))
                    ],
                    options: {groupStyle: {clear: 'both'}}
                } : null,
                showCampaignFields ? {
                    type: 'checkbox',
                    title: 'Archive Campaign',
                    name: 'archived',
                    options: {hideTitle: true, useBorderTop: true},
                } : null,


            ].filter(f => !!f)}
        />

    }
    renderCopyForm() {
        const settings = this.getSettingsObject();
        return <Form
            updatedFields={this.getUpdatedFields()}
            onFieldChange={this.handleFieldChange.bind(this)}
            values={settings}
            fields={[
                {
                    type: 'text',
                    name: 'invitation_subject',
                    title: 'Invitation Subject',
                },
                {
                    type: 'wysiwyg',
                    name: 'invitation_copy',
                    title: 'Invitation Text',
                },

            ].filter(f => !!f)}
        />

    }
    renderBasicsForm() {
        const settings = this.getSettingsObject();
        const showCampaignFields = !this.props.activationFieldsOnly;
        const hashtagsToMonitorTooltip = <Tooltip title={"Posts that include ANY of these hashtags will be pulled into the campaign. Hit TAB or RETURN/ENTER after typing a hashtag to add it."}><i className="v3 icon help" /></Tooltip>
        const hashtagsSourceTooltip = <Tooltip title={"Monitor content only from users in the selected group. Avoid leaving this on 'All Users'."}><i className="v3 icon help" /></Tooltip>

        return <Form
            updatedFields={this.getUpdatedFields()}
            onFieldChange={this.handleFieldChange.bind(this)}
            values={settings}
            fields={[
                {
                    type: 'text',
                    name: 'name',
                    title: 'Brief Name',
                    placeholder: 'eg, Summer Style Campaign',
                },
                {
                    type: 'textarea',
                    name: 'description',
                    title: 'Short Description',
                    placeholder: 'A few sentences to describe the brief, translated for your locale.'
                },
                {
                    type: 'image',
                    name: 'image_id',
                    title: 'Upload Brief Image (at least 800x600)',
                },
                {
                    type: 'file',
                    name: 'invitation_attachment',
                    title: 'Upload Brief PDF (max 12MB)',
                    options: {
                        onChange: this.handleUploadAttachment.bind(this),
                        previewFormatter: (value, remover) => {
                            return <div style={{
                                background: '#f6f6f6',
                                padding: '10px',
                                margin: '10px 0',
                            }}>
                                <a
                                    style={{marginRight: 15}}
                                    className={'v3 icon ban'} onClick={()=>remover(null)} role={'button'} />

                                <a className={'dark'}
                                   href={value.original_url} target={'_blank'}>{value.name}</a>
                            </div>
                        }
                    }
                },
                ALLOW_INTERACTIVE_BRIEFS ? {
                    type: 'select',
                    name: 'brief_id',
                    title: <>Interactive Brief (<a href="/manage/do/page/tools/briefs" target="_blank" className="v3 dark small">Manage</a>)</>,
                    choices: [
                        {value: null, text: '- None -'},
                        ...(this.state.briefs || []).map(brief => ({text: brief.name, value: brief.id}))
                    ]

                } : null,
                {
                    type: 'generic',
                    title: "Timeline",
                    options: {groupStyle: {marginBottom: 0}},
                    content: (
                        <Form
                            usePadding={false}
                            updatedFields={this.getUpdatedFields()}
                            onFieldChange={this.handleFieldChange.bind(this)}
                            values={settings}
                            fields={[
                                showCampaignFields ? {
                                    type: 'date',
                                    name: 'start',
                                    title: 'Start Date',
                                    options: {float: 'left', groupStyle: {marginRight: 20}}
                                } : null,
                                showCampaignFields ? {
                                    type: 'date',
                                    name: 'end',
                                    title: 'End Date',
                                    options: {float: 'left'}
                                } : null,
                            ]}
                        />
                    )
                },
                showCampaignFields ? {
                    type: 'checkbox',
                    name: 'monitor_hashtag',
                    title: "Monitor Hashtags",
                    help: "Disable for global campaigns, enable for regional campaigns."
                } : null,
                (showCampaignFields) ? {
                    type: 'tags',
                    name: 'hashtag',
                    title: (<span>Hashtag(s) to Monitor {hashtagsToMonitorTooltip}</span>),
                    placeholder: 'eg, #summerstyle, #summerstyle2022',
                    style: {width: '100%'}
                } : null,
                (showCampaignFields) ? {
                    type: 'select',
                    name: 'monitor_hashtag_source',
                    title: <span>Hashtag Monitoring Source {hashtagsSourceTooltip}</span>,
                    choices: [
                        {value: null, text: 'All Users'},
                        {value: 'campaign', text: 'Activated Users in This Campaign'},
                        ...(this.props.teams || []).map(team => ({value: `team:${team.id}`, text: `Team: ${team.name}`})),
                        ...(this.props.lists || []).map(list => ({value: `list:${list.id}`, text: `List: ${list.name}`}))
                    ],
                    options: {useBorderTop: false},
                } : null,


            ].filter(f => !!f)}
        />

    }
    renderPane() {
        return (
            <div>
                <div className="col-xs-12 col-md-4">
                    {this.renderNav()}
                </div>
                <div className="col-xs-12 col-md-8">
                    <div className="campaign-settings-toolbox-pane">
                        {this.renderSaveAlert()}
                        {this.renderActiveForm()}
                    </div>
                </div>
            </div>
        );
    }

    renderSaveAlert() {

        let style = {
            position: 'sticky',
            top: 0,
            zIndex: 200,
        };

        if (this.state.isUpdating) {
            return <Alert
                classes={['info']} content={"Saving your changes..."} style={style}
            />
        }

        if (this.state.didUpdate) {
            return <Alert
                classes={['success']} content={"Changes saved!"} style={style}
            />
        }

        if (Object.keys(this.state.pendingUpdates).length > 0) {
            return (
                <Alert
                    classes={['info']}
                    content={<span>You have unsaved changes. <a className="v3 bold" onClick={this.commitUpdates.bind(this)} role="button">Save now.</a></span>}
                    style={style}
                />
            );
        }
    }

    renderContent() {
        return (
            <div className="campaign-settings-toolbox-inner container-fluid container-fullwidth">
                <style dangerouslySetInnerHTML={{__html: `
                    .campaign-settings-toolbox-inner .react-tagsinput {
                        width: 100%;
                    }
                ` }} />

                <div className="row">
                    {this.renderPane()}
                </div>
            </div>
        );
    }

    render() {
        return this.renderContent();
    }

}

const mapStateToProps = (state, props) => {
    return {
        lists: getLists(state, props),
        teams: getTeams(state),
        catalogs: getCatalogs(state),
        forms: getCampaignForms(state, props),
        campaigns: getCampaignsByDate(state, props),
        workflows: getWorkflows(state, props),
        channel: getCurrentChannel(state, props),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchLists: () => dispatch(fetchLists()),
        fetchProductCatalogs: () => dispatch(fetchProductCatalogs()),
        fetchForms: (id) => dispatch(fetchCampaignForms(id)),
        fetchWorkflows: () => dispatch(fetchWorkflowsIfNeeded()),
        fetchReportTemplates: () => dispatch(createChannelInteraction('GetCampaignReportTemplates')),
        fetchBriefs: () => dispatch(createChannelInteraction('BriefBuilder', {action: 'list'})),
    };
};

const ConnectedMACCampaignSettingsForm = connect(mapStateToProps, mapDispatchToProps)(MACCampaignSettingsForm);
ConnectedMACCampaignSettingsForm.propTypes = {
    campaign: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    activationFieldsOnly: PropTypes.bool,
    settings: PropTypes.object.isRequired,
};
export default ConnectedMACCampaignSettingsForm;
