/**
 * Created by bkroger on 10/22/18.
 */

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TableWrapper from '../../../Table/Wrapper';
import BasicCell from "../../../Table/Cell/Basic";
import NumberCell from "../../../Table/Cell/Number";
import DateCell from "../../../Table/Cell/Date";
import {formatNumber, limitChars, makeCellKey} from '../../../../utilities';
import {socialNameForType} from "../../../../utilities/social";
import Toolbox from "../../../Common/Toolbox";
import SectionalToolbox from "../../../Common/SectionalToolbox";
import Button from "../../../Common/Form/Button";
import SocialAuthConnector from './SocialAuthConnector';
import * as URI from 'urijs';
import Alert from "../../../Common/Alert";

class SocialNetworks extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        refreshUser: PropTypes.func.isRequired,
        createInteraction: PropTypes.func.isRequired,
        translate: PropTypes.func.isRequired,
        hideTypes: PropTypes.array
    };

    static defaultProps = {
        hideTypes: [ ]
    }

    constructor(props) {
        super(props);

        this.state = {
            showSocialsToolbox: false,
            showBlogToolbox: false,
            showConsentNotice: false,
            showSingleReconnect: null,
            form: {},
            isFormUpdating: false,
            didFormUpdate: false,
            isRefreshing: {},
            isRemoving: {},
            hasError: false,
            error: null,
            authLinks: {},

            isAttachingSocial: false,
            didAttachSocial: false,
        };

        this.authWindow = null;
        this.handleShowSocialsToolbox = this.handleShowSocialsToolbox.bind(this);
        this.handleAuthWindowMessageForVerifyBlog = this.handleAuthWindowMessageForVerifyBlog.bind(this);
    }

    componentDidMount() {
        this.props.createInteraction(this.props.user.id, 'SocialAuthRequest')
            .then((resp) => {
                if (resp.meta.error) {
                    this.setState({isFetchingAuthLinks: false});
                } else {
                    this.setState({
                        authLinks: resp.data.results, isFetchingAuthLinks: false, didFetchAuthLinks: true
                    })
                }
            });

        window.addEventListener('message', this.handleAuthWindowMessageForVerifyBlog);
    }

    componentWillUnmount() {
        window.removeEventListener('message', this.handleAuthWindowMessageForVerifyBlog);
    }

    handleShowSocialsToolbox() {
        return this.setState({
            showSocialsToolbox: !this.state.showSocialsToolbox
        });
    }

    handleClickReconnect(social) {
        console.log("Clicked reconnect", social);
        this.setState({showSingleReconnect: social.type});
    }

    getItems() {

        const socials = ((this.props.user || {}).socials || [])
            // filter out hidden types
            .filter(social => {
                return !(this.props.hideTypes || []).includes(social.type);
            })
            .map(social => {
                return {
                    ...social,
                    verified_reach: social.followers_count ? social.followers_count : 0,
                    updated: social.crawled_at ? social.crawled_at : null,
                    refresh: this.handleRefreshSocial.bind(this, social.id),
                    remove: this.handleRemoveSocial.bind(this, social.id),
                };
            });

        const blogs = ((this.props.user || {}).blogs || []).map(blog => {
            return {
                ...blog,
                type: 'blog',
                account_name: blog.name,
                verified_reach: blog.verified_reach ? blog.verified_reach : 0,
                updated: blog.latest_post_at ? blog.latest_post_at : null,
                refresh: this.handleRefreshBlog.bind(this, blog.id),
                remove: this.handleRemoveBlog.bind(this, blog.id),
            };
        });

        return [...blogs, ...socials];
    }

    getColumns() {
        const _t = this.props.translate;
        return [
            {
                key: 'type',
                title: _t('contribute.social.table.type.title', 'Type'),
                width: 170,
                default: true,
                sortable: false,
                cell: (row, column) => {
                    const warning = row.item.broken ? <i style={{marginRight: 10}} className="v3 icon error" /> : null;
                    const typeName = row.item.type === 'blog' ? 'Blog' : socialNameForType(row.item.type);
                    const value = <span>{warning}{typeName}</span>;
                    return <BasicCell
                        row={row} column={column} key={makeCellKey(row, column)}
                        value={value}
                    />
                },
            },

            {
                key: 'account_name',
                title: _t('contribute.social.table.name.title', 'Account Name'),
                width: 245,
                default: true,
                sortable: false,
                cell: (row, column) => {
                    const fullName = row.item.name || row.item.slug || row.item.raw;
                    const accName = limitChars(fullName, 24);
                    const reconnectLink = row.item.broken ? <> &bull; <a className="bold" role="button" onClick={this.handleClickReconnect.bind(this, row.item)}>Reconnect</a></> : null;
                    const value = <span>{accName}{reconnectLink}</span>;
                    return <BasicCell
                        row={row} column={column} key={makeCellKey(row, column)}
                        value={value}
                    />
                },
            },

            {
                key: 'verified_reach',
                title: _t('contribute.social.table.reach.title', 'Verified Reach'),
                width: 150,
                default: true,
                sortable: false,
                cell: (row, column) => {
                    if (row.item.type === 'blog' && row.item.is_verified === false) {
                        let value = <a role="button" onClick={() => this.handleClickVerifyBlog(row.item.id)}><i className={'v3 icon error'} /> {_t('contribute.social.table.verify_link', 'Verify Blog')}</a>;
                        if (!(this.state.authLinks || {}).blog) {
                            value = '...';
                        }
                        return <BasicCell row={row} column={column} key={makeCellKey(row, column)}
                                          value={value}
                        />
                    }
                    return <NumberCell row={row} column={column} value={row.item.verified_reach} formatter={formatNumber} />
                },
            },

            {
                key: 'updated',
                title: _t('contribute.social.table.updated.title', 'Updated'),
                width: 140,
                default: true,
                sortable: false,
                cell: (row, column) => <DateCell row={row} column={column} value={row.item.updated} />,
            },
            {
                key: 'refresh',
                title: _t('contribute.social.table.refresh.title', 'Refresh'),
                width: 130,
                default: true,
                sortable: false,
                cell: (row, column) => <BasicCell row={row} column={column} value={this.renderRefreshButton(row.item.id, row.item.refresh)} />,
            },

            {
                key: 'remove',
                title: _t('contribute.social.table.remove.title', 'Remove'),
                width: 125,
                default: true,
                sortable: false,
                cell: (row, column) => <BasicCell row={row} column={column} value={this.renderRemoveButton(row.item.id, row.item.remove)}
                />,
            },
        ];
    }

    handleAuthWindowMessageForVerifyBlog(msg) {
        const {data} = msg;

        if (this.authWindow) {
            this.authWindow.close();
        }

        if (data.from !== 'VerifyBlog') {
            return;
        }

        this.props.createInteraction(this.props.user.id, 'VerifyBlog', {
            token: data.t,
            blog_id: data.id,
            view_id: data.viewId,
        })
            .then(() => this.props.refreshUser(this.props.user.id));

    }


    handleClickVerifyBlog(blogId) {

        let uri = new URI(this.state.authLinks.blog);
        uri.search({
            ...uri.search(true),
            blogId: blogId,
            after: '__pm'
        });
        const url = uri.toString();

        if (this.authWindow) {
            this.authWindow.close();
        }

        this.authWindow = window.open(url, '_blank', 'height=600,width=800');
    }

    handleFieldChange(name, value) {
        this.setState({
            form: {
                ...this.state.form,
                [name]: value,
            },
            hasError: false,
            error: null,
        });
    }

    renderRefreshButton(id, clickHandler) {
        const _t = this.props.translate;
        let icon = <i className="v3 icon reload"></i>;
        let text = _t('contribute.social.table.refresh.refresh', 'Refresh');
        let classes = ['refresh'];

        if (this.state.isRefreshing[id] === true) {
            icon = <i className="v3 icon spinner" />;
            text = _t('contribute.social.table.refresh.refreshing', 'Refreshing...');
            classes.push('disabled');
        }

        return (
            <a role="button" onClick={clickHandler} className={classes.join(' ')}>{icon} {text}</a>
        )
    }

    renderRemoveButton(id, clickHandler) {
        const _t = this.props.translate;
        let icon = <i className="v3 icon cancel"></i>;
        let text = _t('contribute.social.table.remove.remove', 'Remove');
        let classes = ['remove'];

        if (this.state.isRemoving[id] === true) {
            icon = <i className="v3 icon spinner" />;
            text = _t('contribute.social.table.remove.removing', 'Removing...');
            classes.push('disabled');
        }

        return (
            <a role="button" onClick={clickHandler} className={classes.join(' ')}>{icon} {text}</a>
        )
    }

    handleRefreshSocial(id) {
        this.setState({
            isRefreshing: {
                ...this.state.isRefreshing,
                [id]: true
            }
        });

        this.props.createInteraction(this.props.user.id, 'RefreshSocial', {social_id: id})
            .then(() => this.props.refreshUser(this.props.user.id))
            .then(() => this.setState({
                isRefreshing: {
                    ...this.state.isRefreshing,
                    [id]: false
                }
            }));
    }

    handleRemoveSocial(id) {
        this.setState({
            isRemoving: {
                ...this.state.isRemoving,
                [id]: true
            },
        });

        this.props.createInteraction(this.props.user.id, 'RemoveSocial', {social_id: id})
            .then(() => this.props.refreshUser(this.props.user.id))
            .then(() => this.setState({
                isRemoving: {
                    ...this.state.isRemoving,
                    [id]: false
                }
            }));
    }

    handleRefreshBlog(id) {
        //const blogId = ((this.props.user || {}).blogs || {})[0].id;

        this.setState({
            isRefreshing: {
                ...this.state.isRefreshing,
                [id]: true
            }
        });

        this.props.createInteraction(this.props.user.id, 'RefreshBlog', {blog_id: id})
            .then(() => this.props.refreshUser(this.props.user.id))
            .then(() => this.setState({
                isRefreshing: {
                    ...this.state.isRefreshing,
                    [id]: false
                }
            }));
    }

    handleRemoveBlog(id) {
        //const blogId = ((this.props.user || {}).blogs || {})[0].id;

        this.setState({
            isRemoving: {
                ...this.state.isRemoving,
                [id]: true
            }
        });

        this.props.createInteraction(this.props.user.id, 'RemoveBlog',  {blog_id: id})
            .then(() => this.props.refreshUser(this.props.user.id))
            .then(() => this.setState({
                isRemoving: {
                    ...this.state.isRemoving,
                    [id]: false
                }
            }));
    }

    renderConsentToolbox() {
        const _t = this.props.translate;
        const NeverPostSocialText = _t('contribute.social.consent.neverpost', 'We never modify or post on your social account in any way.');
        return (
            <SectionalToolbox
                key={'ep-consent-social-toolbox'}
                title={_t('contribute.social.consent.title', "How We Use This Data")}
                accordion={true}
                sections={[
                    {
                        key: 'social',
                        title: _t('contribute.social.consent.connecting.title', "Connecting to a Social Network"),
                        isExpanded: true,
                        padded: true,
                        content: (
                            <div>
                                {_t('contribute.social.consent.connecting.text', 'When you connect a social account we will add it to your creator profile and make it visible to brands. We capture your basic profile info and stats and may also analyze your recent posts to help brands search for creators with specific focuses.')} <strong>{NeverPostSocialText}</strong>
                            </div>
                        )
                    },
                    {
                        key: 'instagram',
                        title: _t('contribute.social.consent.instagram.title', 'Connecting to Instagram'),
                        isExpanded: false,
                        padded: true,
                        content: (
                            <div>
                                {_t('contribute.social.consent.instagram.text', 'A note on Instagram: We request the "comments" permission from Instagram. We use this permission only for engagement analysis and never under any circumstances post to your account or modify it in any way. This permission is required for engagement analysis because Instagram does not make a distinction in permissions between reading comments and posting comments.')}
                            </div>
                        )
                    },
                    {
                        key: 'blog',
                        title: _t('contribute.social.consent.blog.title', 'Connecting a Blog'),
                        isExpanded: false,
                        padded: true,
                        content: (
                            <div>
                                <p>{_t('contribute.social.consent.blog.text', 'When you connect a blog we periodically check to see if the blog is reachable by the URL you\'ve provided, take a screenshot of how the blog looks, and will analyze the content to make your blog more easily discoverable by brands.')}</p>
                                <p>{_t('contribute.social.consent.blog.gatext', 'When you connect to Google Analytics we will capture some basic info on your traffic, like your average monthly visitors and your audience age/gender ratio and top interests. We use this data to help brands search for creators with specific audiences.')}</p>
                            </div>

                        )
                    },
                    {
                        key: 'consent',
                        title: _t('contribute.social.consent.consent.title', "Your Rights"),
                        isExpanded: false,
                        padded: true,
                        content: (
                            <div>
                                <p>{_t('contribute.social.consent.consent.text', 'By connecting a social account, blog, or Google analytics, you give consent to analyze your data for a period of one year, at which point your consent will automatically be revoked. You can opt-out at any time by clicking the "Remove" button next to any social or blog account, at which point we will stop processing your data. Additionally, if your followers count looks out of date, you can request an immediate re-analysis of that account by clicking the "Refresh" button.')}</p>
                                <p><strong>{NeverPostSocialText}</strong> {_t('contribute.social.consent.consent.processing', 'Any processing we perform related to your social accounts is "read-only". We will never post a comment on your behalf, never change your settings on a social network, and never make any sort of change to your account whatsoever. The data we process is related to your interaction with this platform only.')}</p>
                            </div>
                        )
                    }
                ]}
                onClose={() => this.setState({showConsentNotice: false})}
                style={{
                    width: 600,
                }}
            />
        );
    }

    renderSocialsToolbox() {
        return (
          <Toolbox
              key={'ep-add-social-toolbox'}
              title={this.props.translate('contribute.social.add.button', "Add Social")}
              content = {
                  <SocialAuthConnector
                      user={this.props.user}
                      onDidAttach={()=>{
                          this.setState({isAttachingSocial: false, didAttachSocial: true});
                          this.props.refreshUser(this.props.user.id);
                      }}
                      onFail={(error)=>{
                          this.setState({isAttachingSocial: false, hasError: true, error: error});
                          this.props.refreshUser(this.props.user.id);
                      }}
                      toolboxAddlClasses={"toolbox-fixed toolbox-sm"}
              />
              }
              onClose={this.handleShowSocialsToolbox}
              addlClasses={'add-social-toolbox'}
          />
        );
    }

    renderButtons() {
        const _t = this.props.translate;
        return [
            <Button
                key={'ep-add-acct-btn'}
                classes={['v3', 'btn-primary', 'small', 'bold']}
                content={_t('contribute.social.add_account', 'Add Account')}
                onClick={this.handleShowSocialsToolbox}
            />,
            <Button
                key={'ep-show-consent-btn'}
                classes={['v3', 'btn-secondary', 'small', 'bold', (this.state.showConsentNotice ? 'active' : '')]}
                content={<span><i className="fa fa-question-circle" /> {_t('contribute.social.consent.button', 'About Your Data')}</span>}
                onClick={() => this.setState({showConsentNotice: !this.state.showConsentNotice})}
            />,

        ];
    }

    renderPopups() {
        let popups = [];
        if (this.state.showSocialsToolbox) {
            popups.push(this.renderSocialsToolbox());
        }
        if (this.state.showConsentNotice) {
            popups.push(this.renderConsentToolbox());
        }
        if (this.state.showSingleReconnect) {
            popups.push(this.renderSingleReconnect())
        }
        return popups;
    }

    renderSingleReconnect() {
        const rawType = this.state.showSingleReconnect;
        const niceType = socialNameForType(rawType);
        return (
            <Toolbox
                title={"Reconnect Account"}
                addlClasses="toolbox-fixed toolbox-sm"
                content={
                    <div style={{minHeight: 500}}>
                        <Alert content="Click below to reconnect your account." classes={['info']} />
                        <div style={{margin: '50px 0'}}>
                            <SocialAuthConnector
                                user={this.props.user}
                                types={[niceType]}
                                inline={false}
                                buttonClass={'v3 btn btn-primary medium'}
                                autolaunch={true}
                                authLinks={this.state.authLinks}
                            />
                        </div>
                    </div>
                }
                onClose={() => this.setState({showSingleReconnect: null})}
            />
        );
    }

    renderInfoBoxes() {
        const _t = this.props.translate;
        const igNoteTitle = _t('contribute.social.info.instagram.title', 'Connecting to Instagram');
        const igNoteText = _t('contribute.social.info.instagram.text', "To connect an Instagram account, Facebook requires you to have an Instagram Business or Instagram Creator account that is also connected to a Facebook Page.");
        const igNoteUrl = 'https://www.facebook.com/help/1148909221857370/?helpref=search&query=link%20instagram%20to%20facebook&search_session_id=e54a4829748d2647b7c581fd2469dfb1&sr=0';
        const viewInstructions = _t('contribute.social.info.view_instructions', "View Detailed Instructions");

        const ytNoteTitle = _t('contribute.social.info.youtube.title', 'Connecting to YouTube');
        const ytNoteText = _t('contribute.social.info.youtube.text', 'When you connect to YouTube you will be asked to grant permission to your content and comments. We never post or modify in any way; we only read and analyze the content that you submit.');

        return (
            <>
                <Alert
                    style={{marginTop: 60, maxWidth: 1000, backgroundColor: '#EEE', color: '#333'}}
                    classes={['info']}
                    content={(
                        <>
                            <strong>{igNoteTitle}</strong>: {igNoteText} <a style={{color: 'black', textDecoration: 'underline'}} className="v3 bold" href={igNoteUrl} target="_blank" rel="noopener">{viewInstructions}</a>
                        </>
                    )}
                />
                <Alert
                    style={{marginTop: 20, maxWidth: 1000, backgroundColor: '#EEE', color: '#333'}}
                    classes={['info']}
                    content={(
                        <>
                            <strong>{ytNoteTitle}</strong>: {ytNoteText}
                        </>
                    )}
                />
            </>
        );
    }
    render() {
        return (
            <div className="pane-inner-content social-networks">
                <TableWrapper
                    title={this.props.translate('contribute.social.table.title', "Social Networks and Blogs")}
                    items={this.getItems()}
                    columns={this.getColumns()}
                    stickySortTabs={true}
                    buttons={this.renderButtons()}
                    showPagination={false}
                    popups={this.renderPopups()}
                />
                {this.renderInfoBoxes()}
            </div>
        );
    }
}

export default SocialNetworks;
