import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {fetchAuthenticated} from "../../../actions/auth";
import {createActivationInteraction} from "../../../actions/activations";
import {fetchActivation} from "../../../actions";
import Alert from "../../Common/Alert";
import UploadVideos from "./Templates/UploadVideos";

function StandaloneVideoUploader({campaign, user, translate, fetchAuthenticated, fetchActivation, createActivationInteraction}) {
    const [activationId, setActivationId] = useState(null);
    const [activation, setActivation] = useState(null);
    const [didCompleteUpload, setDidCompleteUpload] = useState(false);
    const ensureActivation = async () => {
        if (!activationId) {

            const res = await fetchAuthenticated(`/contribute/api/campaign/${campaign.id}/activation`, {
                method: 'POST',
            });
            const json = await res.json();
            setActivationId(json.data.id);
        }
    }

    const refreshActivation = async () => {
        if (activationId) {
            const res = await fetchActivation(activationId);
            setActivation(res.data);
        }
    }


    useEffect(() => {
        ensureActivation();
    }, [campaign, user]);

    useEffect(() => {
        refreshActivation();
    }, [activationId]);

    if (!activation) {
        return <Alert classes={['info']} content={"Loading, please wait..."} />;
    }

    return (
        <div className={"standalone-video-uploader"}>
            {didCompleteUpload && <Alert classes={['success']} content={"Your video has been uploaded successfully. You can close this popup."} />}
            <UploadVideos
                alwaysAllowUpload={true}
                activation={activation}
                campaign={campaign}
                user={user}
                translate={translate}
                refreshActivation={refreshActivation}
                createInteraction={createActivationInteraction}
                onComplete={() => {
                    setDidCompleteUpload(true);
                }}
            />
            <style jsx>{`
                .standalone-video-uploader .title, .standalone-video-uploader h4 { display: none; }
            `}</style>

        </div>
    );

}

const mapStateToProps = (state) => undefined;
const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    fetchActivation: fetchActivation,
    createActivationInteraction: createActivationInteraction
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(StandaloneVideoUploader);