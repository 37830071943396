import React, {useState, useEffect, useMemo} from 'react';
import MyCampaignLeaderboard from "../../../MAC/Creator/MyCampaignLeaderboard";
import MyPerformanceCharts from "../../../MAC/Creator/MyPerformanceCharts";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchCampaigns} from "../../../../actions";
import {getCampaigns} from "../../../../selectors/campaigns";
import BriefsTable from "../../../MAC/Creator/BriefsTable";
import {getCurrentChannel, getCurrentUser, getPresenceTeam} from "../../../../selectors/presence";
import { magicFilterBriefs } from "../../../../utilities/mac";
import {translate} from "../../../../actions/i18n";
import {Link} from "react-router-dom";

function MACAffiliatePage({fetchAuthenticated, campaigns, user, team, channel, translate}) {
    const backWord = translate('creator.words.back', 'Back');
    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                <div className="briefs-page-wrapper">

                    <div className="page-title-wrapper briefs-title-wrapper">
                        <h1 className="red-indent">Shoppable Page
                            <Link to="/contribute/do/page/dash/home" className="seeall">{backWord}<i className="v3 icon fa-arrow-left" /> </Link>
                        </h1>
                    </div>

                    <div className="briefs-table-wrapper">
                    </div>

                </div>

            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    campaigns: getCampaigns(state, props),
    user: getCurrentUser(state, props),
    team: getPresenceTeam(state, props),
    channel: getCurrentChannel(state, props),
});
const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    translate: translate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACAffiliatePage);