import React from "react";

function NiceOfferType({children, className}) {
    // children should be text like "commission" or "discount"
    let niceName = children;
    switch (children) {
        case 'commission':
            niceName = 'Commission';
            break;
        case 'discount':
            niceName = 'Discount';
            break;
        case 'discount-pct':
            niceName = 'Discount (%)';
            break;
        case 'freebie':
            niceName = 'Freebie';
            break;
        case 'promotion':
            niceName = 'Promotion';
            break;
        case 'event':
            niceName = 'Event';
            break;
        default:
            niceName = 'Offer';
    }

    return <span className={className}>{niceName}</span>;
}


export default NiceOfferType;