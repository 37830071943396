import React, {useState, useEffect, useMemo} from 'react';
import MyCampaignLeaderboard from "../../../MAC/Creator/MyCampaignLeaderboard";
import MyPerformanceCharts from "../../../MAC/Creator/MyPerformanceCharts";
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../../../actions/auth";
import {fetchCampaigns} from "../../../../actions";
import {getCampaigns} from "../../../../selectors/campaigns";
import BriefsTable from "../../../MAC/Creator/BriefsTable";
import {getCurrentChannel, getCurrentUser, getPresenceTeam} from "../../../../selectors/presence";
import { magicFilterBriefs } from "../../../../utilities/mac";
import {translate} from "../../../../actions/i18n";
import {Link} from "react-router-dom";
import TableWrapper from "../../../Table/Wrapper";
import Avatar from "../../../Common/Avatar";
import NiceOfferType from "../../../Affiliate/NiceOfferType";
import MyOffersTable from "../../../Affiliate/MyOffersTable";

function OfferCard({offer}) {
    const endDate = new Date(offer.end);
    return (
        <div className="affiliate-offer" key={offer.id}>
            <NiceOfferType className="badge">{offer.offerType}</NiceOfferType>
            {offer.image && <img src={offer.image} alt={offer.name} className={"offer-img"} />}
            <h3>{offer.name}</h3>
            <p className="description">{offer.description}</p>
            <p className="dates">Valid until {endDate.toLocaleDateString()}</p>
            <Link className="cta" to={`/contribute/do/page/dash/aff_offer?id=${offer.id}`}>View and Accept</Link>
        </div>

    );
}
/**
 * A link looks like this:
 * {
 *     "campaignID": "0",
 *     "categoryID": "0",
 *     "categoryName": "Default",
 *     "linkID": "10004036",
 *     "linkName": "EVERGREEN: Please Use This Link for Clinique Store Page",
 *     "mid": "24775",
 *     "nid": "1",
 *     "clickURL": "https://click.linksynergy.com/fs-bin/click?id=XnusZEo64SQ&offerid=1506671.10004036&type=3",
 *     "endDate": "May 01, 2030",
 *     "landURL": "https://www.clinique.com/offers",
 *     "showURL": "https://ad.linksynergy.com/fs-bin/show?id=XnusZEo64SQ&bids=1506671.10004036&type=3",
 *     "startDate": "May 01, 2024",
 *     "textDisplay": "Sunny Day Staples set. 5 full sizes. Includes Moisture Surge™ SPF 28, Black Honey Lip Gloss, and more. $45 with eligible purchase! A total $180 Value.T&Cs apply. See site for details."
 *   }
 */
function LinkCard({link}) {

    return (
        <div className="affiliate-offer" key={link.linkID}>
            <span className="badge">Offer</span>
            <h3>{link.linkName}</h3>
            <p className="description">{link.textDisplay}</p>
            <p className="dates">Valid from {link.startDate} to {link.endDate}</p>
            <a className="cta" href={link.clickURL} target="_blank" rel="noopener noreferrer">Shop Now</a>
        </div>
    );
}

function MACAffiliateOffers({fetchAuthenticated, campaigns, user, team, channel, translate}) {
    const [linkRes, setLinkRes] = useState('');
    const [offers, setOffers] = useState([]);
    const backWord = translate('creator.words.back', 'Back');
    const totalMatches = 1;
    const columns = [];
    const pageNumber = 1;
    const totalPages = 1;
    const onPageChange = () => {};

    const linksParser = new DOMParser();
    const linksXmlDoc = linksParser.parseFromString(linkRes || '', "application/xml");
    const linksTags = linksXmlDoc.getElementsByTagName('ns1:return');

    const fetchOffers = async () => {
        try {
            const resp = await fetchAuthenticated('/contribute/api/offer');
            const json = await resp.json();
            setOffers(json.data);
        } catch (e) {
            console.error(e);
        }
    }
    const fetchLinks = async () => {
        try {
            const startDate = '01012010';
            const endDate = '01012030';
            const resp = await fetchAuthenticated(
                '/contribute/do/widget/dash/aff_proxy?path='
                + encodeURIComponent(`/linklocator/1.0/getTextLinks/-1/-1/${startDate}/${endDate}/-1/1`)
            );
            const rawXml = await resp.text();
            setLinkRes(rawXml);
        } catch (e) {
            setLinkRes(e.message);
        }
    }

    const links = useMemo(() => {
        return Array.from(linksTags).map((linkTag) => {
            const link = {};
            for (let i = 0; i < linkTag.childNodes.length; i++) {
                const child = linkTag.childNodes[i];
                const cleanTagName = child.tagName.replace('ns1:', '');
                if (child.nodeType === 1) {
                    link[cleanTagName] = child.textContent;
                }
            }
            return link;
        });
    }, [linksTags]);

    useEffect(() => {
        const doRun = async () => {
            await fetchOffers();
            await fetchLinks();
        }
        doRun();
    }, []);

    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                <div className="briefs-page-wrapper">

                    <div className="page-title-wrapper briefs-title-wrapper">
                        <h1 className="red-indent">Affiliate Offers
                            <Link to="/contribute/do/page/dash/home" className="seeall">{backWord}<i className="v3 icon fa-arrow-left" /> </Link>
                        </h1>
                    </div>

                    <div className="briefs-table-wrapper">
                        <MyOffersTable />
                    </div>

                    <div className="briefs-table-wrapper">

                        <TableWrapper
                            title={"Exclusive Offers"}
                            headerIcon={<span className="large badge">{offers.length}</span>}
                            items={offers}
                            columns={columns}
                            page={1}
                            pages={1}
                            showPagination={false}
                            onPageChange={onPageChange}
                            viewAsCards={true}
                            innerClasses={['affiliate-offers-banner']}
                            cardRenderer={({item}) => <OfferCard offer={item} key={item.id} />}
                        />

                    </div>

                    <div className="briefs-table-wrapper">

                            <TableWrapper
                                title={"Advertiser Offers"}
                                headerIcon={<span className="large badge">{links.length}</span>}
                                items={links}
                                columns={columns}
                                page={1}
                                pages={1}
                                showPagination={false}
                                onPageChange={onPageChange}
                                viewAsCards={true}
                                innerClasses={['affiliate-offers-banner']}
                                cardRenderer={({item}) => <LinkCard link={item} key={item.linkID} />}
                            />


                    </div>

                </div>

            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    campaigns: getCampaigns(state, props),
    user: getCurrentUser(state, props),
    team: getPresenceTeam(state, props),
    channel: getCurrentChannel(state, props),
});
const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    translate: translate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACAffiliateOffers);