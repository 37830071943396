import React, {useState, useEffect, useMemo} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../../../actions/auth";
import {getCampaigns} from "../../../../selectors/campaigns";
import {getCurrentChannel, getCurrentUser, getPresenceTeam} from "../../../../selectors/presence";
import {translate} from "../../../../actions/i18n";
import {Link} from "react-router-dom";
import NiceOfferType from "../../../Affiliate/NiceOfferType";
import Form from "../../../Common/Form/Form";

function NiceCommissionAmount({commissionRate, commissionAmount}) {
    let out = 'With ';
    if (commissionRate && commissionAmount) {
        out += `${commissionRate}% + $${commissionAmount} commission`;
    } else if (commissionRate) {
        out += `${commissionRate}% commission`;
    } else if (commissionAmount) {
        out += `$${commissionAmount} commission`;
    } else {
        out += 'no commission';
    }
    return out;
}

function MACAffiliateOffer({offerId, fetchAuthenticated, translate}) {
    const [offer, setOffer] = useState(null);
    const [activations, setActivations] = useState([]);
    const [activation, setActivation] = useState(null);
    const [didInit, setDidInit] = useState(false);
    const backWord = translate('creator.words.back', 'Back');

    const fetchOffer = async () => {
        try {
            const resp = await fetchAuthenticated('/contribute/api/offer/'+offerId);
            const json = await resp.json();
            setOffer(json.data.offer);
            setActivations(json.data.activations);
            if (json.data.myActivation) {
                setActivation(json.data.myActivation);
            }
        } catch (e) {
            console.error(e);
        }
    }
    useEffect(() => {
        fetchOffer().then(() => setDidInit(true));
    }, [offerId]);

    const imageBgStyle = {
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundColor: "#eee",
        backgroundImage: `url(${offer && offer.image})`,
    };

    if (!didInit || !offer) {
        return <p><em>Loading...</em></p>
    }

    const endDate = offer.end ? new Date(offer.end) : null;
    const handleClickAccept = async () => {
        const url = `/contribute/api/offer/${offerId}/accept`;
        const resp = await fetchAuthenticated(url, {method: 'POST'});
        if (resp.ok) {
            const json = await resp.json();
            setActivation(json.data);
        } else {

        }



    }

    return (
        <div className="mac-wrapper">
            <div className="dashboard">

                <div className="brief-page-wrapper">

                    <div className="page-title-wrapper briefs-title-wrapper">
                        <h1 className="red-indent">Exclusive Offer
                            <Link to="/contribute/do/page/dash/aff_offers" className="seeall">{backWord}<i className="v3 icon fa-arrow-left" /> </Link>
                        </h1>
                    </div>

                    <div className="brief-preview-wrapper">
                        <div className="brief-preview-left">

                            <div className="brief-preview-img-wrapper">
                                <div className="brief-preview-img" style={imageBgStyle} />
                            </div>

                            <div className="flex-parent" style={{textAlign: 'center'}}>
                                <div>Offer from {offer.brandName}</div>

                                {endDate && <div>Valid until {endDate.toLocaleDateString()}</div>}
                            </div>

                        </div>
                        <div className="brief-preview-right">
                            <h2>{offer.name}</h2>
                            {(offer.offerType && offer.offerType !== 'null') && <p><NiceOfferType>{offer.offerType}</NiceOfferType></p>}
                            <p><NiceCommissionAmount commissionAmount={offer.commissionAmount} commissionRate={offer.commissionRate} /></p>
                            {(offer.description && offer.description !== 'null') && <p>{offer.description}</p>}
                            {(offer.offerUrl && offer.offerUrl !== 'null') && <a href={offer.offerUrl} target="_blank" rel="noopener noreferrer">View Offer</a>}

                            {!activation && (<div className="accept-btn-wrapper" style={{marginTop: '2em'}}>
                                <a onClick={handleClickAccept} role="button" className="v3 btn tidal-btn btn-primary large">Accept Offer and Get Link</a>
                            </div>)}

                            {activation && (<div style={{marginTop: '2em'}}>
                                <Form
                                    usePadding={false}
                                    values={activation}
                                    fields={[
                                        {
                                            type: 'text',
                                            name: 'shortUrl',
                                            title: 'Your Affiliate Link',
                                            help: "Copy and paste this link to share the offer. It's unique to you and will track your referrals.",
                                            options: {
                                                readOnly: true,
                                            }
                                        }
                                    ]}
                                />
                            </div>)}
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

const mapStateToProps = (state, props) => ({
    campaigns: getCampaigns(state, props),
    user: getCurrentUser(state, props),
    team: getPresenceTeam(state, props),
    channel: getCurrentChannel(state, props),
});
const mapDispatchToProps = (dispatch, props) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
    translate: translate,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MACAffiliateOffer);
