import React, {useEffect, useState} from 'react';
import {bindActionCreators} from "redux";
import {connect} from 'react-redux';
import {fetchAuthenticated} from "../../actions/auth";
import TableWrapper from "../Table/Wrapper";
import BasicCell from "../Table/Cell/Basic";
import {formatDollar, formatNumber, makeCellKey} from "../../utilities";
import MiniProfile from "../Common/MiniProfile";
import _get from "lodash/get";

function MyOffersTable({fetchAuthenticated}) {
    const [didInit, setDidInit] = useState(false);
    const [activations, setActivations] = useState([]);
    const [details, setDetails] = useState({});
    const [queuedFetches, setQueuedFetches] = useState([]);
    const [currentlyFetching, setCurrentlyFetching] = useState(null);
    const [fetchedAlready, setFetchedAlready] = useState([]);

    const fetchMyActivations = async () => {
        try {
            const resp = await fetchAuthenticated('/contribute/api/offer/_/mine');
            const json = await resp.json();
            setActivations(json.data);
        } catch (e) {
            console.error(e);
        }
    };

    // initial fetch
    useEffect(() => {
        fetchMyActivations()
            .then(() => setDidInit(true));
    }, []);

    // fetch details for each activation in the queue
    useEffect(() => {
        if (queuedFetches.length > 0 && !currentlyFetching && didInit) {
            fetchNextDetails();
        }
    }, [queuedFetches, currentlyFetching, didInit]);

    const addToFetchQueue = (activationId) => {
        // make sure it's not already in queuedFetches, currentlyFetching, or fetchedAlready
        if (queuedFetches.includes(activationId) || currentlyFetching === activationId || fetchedAlready.includes(activationId)) {
            return;
        }
        console.log("Adding to fetch queue: " + activationId);
        setQueuedFetches(prev => [...prev, activationId]);
    }

    const fetchNextDetails = () => {
        if (queuedFetches.length > 0) {
            const activationId = queuedFetches[0];
            const act = activations.find(a => a.id === activationId);
            fetchDetails(act).then(() => {
                console.log("Fetched details for: " + activationId);

            });
        }
    }

    const fetchDetails = async (activation) => {
        console.log("Fetching details for: " + activation.id);
        const activationId = activation.id;
        const offerId = activation.campaign_id;
        if (currentlyFetching === activationId || fetchedAlready.includes(activationId)) {
            return;
        }
        setCurrentlyFetching(activationId);
        try {
            const resp = await fetchAuthenticated('/contribute/api/offer/' + offerId);
            const json = await resp.json();
            setDetails(prev => ({...prev, [activationId]: json.data}));
            setFetchedAlready(prev => [...prev, activationId]);
        } catch (e) {
            console.error(e);
        } finally {
            setQueuedFetches(prev => prev.filter(id => id !== activationId));
        }

        // return a short timeout promise
        return new Promise((resolve) => {
            setTimeout(() => {
                setCurrentlyFetching(null);
                resolve();
            }, 1000);
        });
    }

    const handleRowVisibilityChange = (row) => {
        if (row.isVisible || row.wasEverVisible) {
            addToFetchQueue(row.item.id);
        }
    }

    const getActivationDetail = (activationId, key, defText=0) => {
        return _get(details, [activationId, 'myActivation', key], defText);
    }
    const getOfferDetail = (activationId, key, defText=0) => {
        return _get(details, [activationId, 'offer', key], defText);
    }

    const columns = [
        {
            key: 'created_at',
            title: "Accepted On",
            width: 160,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const date = new Date(row.item.accepted_at || row.item.created_at);
                const dateString = date.toLocaleDateString();
                return (
                    <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={dateString} />
                )
            }
        },
        {
            key: "miniprofile",
            title: "Offer",
            width: 300,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const mp = <MiniProfile
                    primary={getOfferDetail(row.item.id, 'name')}
                    secondary={getOfferDetail(row.item.id, 'brandName')}
                    imageUrl={getOfferDetail(row.item.id, 'image')}
                    square={true}
                />
                return (
                    <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={mp} />
                )
            }
        },
        {
            key: "clicks",
            title: "Clicks",
            width: 160,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const value = formatNumber(getActivationDetail(row.item.id, 'clicks'));
                return (
                    <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={value} />
                )
            }
        },
        {
            key: "orders",
            title: "Orders",
            width: 160,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const value = formatNumber(getActivationDetail(row.item.id, 'conversions'));
                return (
                    <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={value} />
                )
            }
        },
        {
            key: "earnings",
            title: "Earnings",
            width: 160,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const value = formatDollar(getActivationDetail(row.item.id, 'conversion_value'));
                return (
                    <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={value} />
                )
            }
        },
        {
            key: 'affiliate_link',
            title: 'Affiliate Link',
            width: 400,
            default: true,
            sortable: false,
            cell: (row, column) => {
                const value = getActivationDetail(row.item.id, 'shortUrl');
                return (
                    <BasicCell row={row} column={column} key={makeCellKey(row, column)} value={value} />
                )
            }
        }

    ];

    if (!didInit || !activations) {
        return null;
    }

    return (
        <div style={{marginBottom: "2rem"}}>
        <TableWrapper
            title="My Accepted Offers"
            headerIcon={<span className="large badge">{activations.length}</span>}
            items={activations || []}
            columns={columns}
            showPagination={false}
            onRowVisibilityChange={handleRowVisibilityChange}
        />
        </div>
    )

}

const mapStateToProps = (state) => {
    return { };
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    fetchAuthenticated: fetchAuthenticated,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MyOffersTable);