import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {base64_png_1pxSolidEEE, getImageUrl, proxyImageUrl} from "../../utilities";

export default class Avatar extends Component {

    static defaultProps = {
        classes: [],
        style: {},
    };

    static propTypes = {
        image: PropTypes.any,
        url: PropTypes.string,
        classes: PropTypes.array,
        style: PropTypes.object,
        onClick: PropTypes.func,
        useProxy: PropTypes.bool,
        onMouseEnter: PropTypes.func,
        onMouseLeave: PropTypes.func
    };

    state = {
        isBroken: false,
        isChecking: false,
        useProxy: false,
    }

    componentDidMount() {
        // this.checkIsBroken();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.url !== this.props.url) {
            // this.checkIsBroken();
        }
    }

    handleMouseEnter(event) {
        if (this.props.onMouseEnter) {
            this.props.onMouseEnter(event);
        }
    }

    handleMouseLeave(event) {
        if (this.props.onMouseLeave) {
            this.props.onMouseLeave(event);
        }
    }

    checkIsBroken() {

        if (this.state.isChecking) {
            return;
        }

        const minArea = 20000;
        const killImage = () => {
            this.setState({isBroken: true, isChecking: false});
        };

        const url = this.getImageUrl();
        this.setState({isChecking: true});

        let img = new Image();

        img.onload = (e) => {
            this.setState({isChecking: false});
            const height = e.target.naturalHeight;
            const width = e.target.naturalWidth;
            const area = height * width;
            if (area < minArea) {
                killImage();
            }
        };

        img.onerror = (e) => {
            killImage();
        };

        img.src = url;


    }

    getImageUrl() {
        const {image, url, useProxy} = this.props;
        let output = null;

        if (this.state.isBroken) {
            output = null;
        }

        if (url) {
            output = url;
        }

        if (image) {
            output = getImageUrl(image);
        }

        if (useProxy && output) {
            output = proxyImageUrl(output);
        }

        return output;
    }

    getStyle() {

        const url = this.getImageUrl();

        let style = {
            backgroundColor: '#EEE',
            backgroundImage: url ? 'url(' + url + ')' : 'none',
            backgroundPosition: 'center',
            ...this.props.style,
        };

        return style;

    }

    render() {
        let classes = ['v3', 'avatar', ...this.props.classes];
        return (
            <div
                onMouseEnter={this.handleMouseEnter.bind(this)}
                onMouseLeave={this.handleMouseLeave.bind(this)}
                className={classes.join(' ')}
                style={this.getStyle()}
                onClick={this.props.onClick}> </div>
        );
    }
}