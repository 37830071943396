import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import MACCreatorDashboard from "../components/Pages/MAC/Creator/Dashboard";
import MACCreatorAchievementsPage from "../components/Pages/MAC/Creator/Achievements";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {fetchAuthenticated} from "../actions/auth";
import {fetchPresence} from "../actions/presence";
import {fetchBadges, fetchCampaigns} from "../actions";
import MACCreatorBriefsPage from "../components/Pages/MAC/Creator/Briefs";
import * as URI from "urijs";
import MACCreatorThreadPage from "../components/Pages/MAC/Creator/Thread";
import MACCreatorCommunityPage from "../components/Pages/MAC/Creator/Community";
import MACCreatorProfilePage from "../components/Pages/MAC/Creator/Profile";
import MACCreatorBriefPage from "../components/Pages/MAC/Creator/Brief";
import MACCreatorTrendingPage from "../components/Pages/MAC/Creator/Trending";
import MACAffiliatePage from "../components/Pages/MAC/Creator/AffiliatePage";
import MACAffiliateSearch from "../components/Pages/MAC/Creator/AffiliateSearch";
import MACAffiliateOffers from "../components/Pages/MAC/Creator/AffiliateOffers";
import MACAffiliateOffer from "../components/Pages/MAC/Creator/AffiliateOffer";

function MACRoutes({fetchBadges, fetchCampaigns, theme}) {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(function() {
        // Modify body tag with mac class
        const body = document.getElementsByTagName('body')[0];
        body.className = body.className + ' mac-creator ' + theme;

        setIsLoading(false);
        Promise.all([
            fetchBadges(),
            fetchCampaigns()
        ]).then(() => { })
    }, []);

    const getQueryObject = () => {
        const uri = new URI(window.location.href);
        return uri.search(true);
    };

    const getQueryId = () => {
        const obj = getQueryObject();
        if (!obj) return null;
        return obj.id || null;
    };

    if (isLoading) {
        return (
            <div className="mac-loading-screen">Loading your dashboard...</div>
        );
    }

    return (
        <Router>
            <Switch>
                <Route exact path="/contribute/do/page/dash/aff_page" component={MACAffiliatePage} />
                <Route exact path="/contribute/do/page/dash/aff_search" component={MACAffiliateSearch} />
                <Route exact path="/contribute/do/page/dash/aff_offers" component={MACAffiliateOffers} />
                <Route exact path="/contribute/do/page/dash/aff_offer" render={() => {
                    const id = getQueryId();
                    return <MACAffiliateOffer offerId={id} />
                }} />

                <Route exact path="/contribute/do/page/dash/mac" component={MACCreatorDashboard} />
                <Route exact path="/contribute/do/page/dash/home" component={MACCreatorDashboard} />
                <Route exact path="/contribute/do/page/dash/achievements" component={MACCreatorAchievementsPage} />
                <Route exact path="/contribute/do/page/dash/briefs" component={MACCreatorBriefsPage} />
                <Route exact path="/contribute/do/page/dash/community" component={MACCreatorCommunityPage} />
                <Route exact path="/contribute/do/page/dash/trending" component={MACCreatorTrendingPage} />
                <Route exact path="/contribute/do/page/dash/profile" component={MACCreatorProfilePage} />
                <Route exact path="/contribute/do/page/dash/brief" render={() => {
                    const id = getQueryId();
                    return <MACCreatorBriefPage campaignId={id} />
                }} />
                <Route exact path="/contribute/do/page/dash/thread" render={() => {
                    const id = getQueryId();
                    return <MACCreatorThreadPage threadId={id} />
                }} />
            </Switch>

        </Router>
    );
}


export default connect(
    function (state, props) {
        return { };
    },
    function (dispatch) {
        return bindActionCreators({
            fetchAuthenticated: fetchAuthenticated,
            fetchPresence: fetchPresence,
            fetchBadges: fetchBadges,
            fetchCampaigns: fetchCampaigns
        }, dispatch);
    }
)(MACRoutes);